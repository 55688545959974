import { makeStyles } from '@material-ui/styles';
import { MEDIA_PATH } from '../../../Utils';

const ContactStyle = makeStyles((theme) => ({
  root: {
    maxWidth: `1400px`,
    margin: `3rem auto !important`,
    '& *': {
      fontFamily: `Arial`,
    },
    '& h1': {
      fontSize: `2rem`,
      color: `#151b74`,
      margin: `2rem 0`,
      textTransform: `uppercase`,
    },
  },
  noPadding: {
    padding: `0 !important`,
  },
  gmaps: (props) => ({
    backgroundImage: `url(${MEDIA_PATH}/${props.headerImg}`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    textAlign: `center`,
    padding: `0 !important`,
    // height: `500px`,
    height: `0`,
    width: `100%`,
    margin: `0 0 2rem`,
  }),
  gigi: {
    backgroundPosition: `center center`,
  },
  gmapsWrap: {
    margin: `0`,
    position: `relative`,
    '.gmapsLabel': {
      position: `absolute`,
      bottom: `0`,
      left: `0`,
    },
  },
}));

export default ContactStyle;
