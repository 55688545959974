// MATERIAL UI
import { Card, CardContent, Grid } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { getHistoryPathArray } from '../../../Helpers';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
// UTILS
import { GALLERY_PATH, REST, rURL } from '../../../Utils/index';
// STYLE
import RezultateStyles from './rezultate.style-new';

// eslint-disable-next-line
let slug, title, tabsTitle;

const RezultateEntryComponentNew = (props) => {
  const { history } = props;

  const [headerImg, setHeaderImg] = useState(null);

  const classes = RezultateStyles({ headerImg });

  // eslint-disable-next-line
  const [imagesForSlider, setImagesForSlider] = useState(null);
  // eslint-disable-next-line
  const [docs, setDocs] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const loadHeaderImage = async () => {
    const answer = await REST.get(`${rURL}/public/getHeadpic/3`);
    const { pic_id_from_media_library } = answer.data.data;
    setHeaderImg(pic_id_from_media_library);
  };

  useEffect(() => {
    const entrySlug = getHistoryPathArray(history);
    switch (entrySlug.slice(-1)[0]) {
      case 'ateliere-de-lucru':
        slug = 'ateliere';
        title = 'Ateliere de lucru';
        tabsTitle = 'Etape';
        break;
      case 'bune-practici':
        slug = 'practici';
        title = 'Bune practici';
        tabsTitle = 'Interviuri';
        break;
      case 'schimb-de-experienta':
        slug = 'experiente';
        title = 'Schimb de experiență';
        tabsTitle = 'Povesti';
        break;
      default:
        slug = '';
        title = '';
        break;
    }

    loadEntry(slug);
    getGalleryImages(slug);
    getGalleryDocs(slug);
    getTabs(slug);

    loadHeaderImage();
    // eslint-disable-next-line
  }, []);

  const getGalleryImages = async (slug) => {
    const answer = await REST.get(
      `${rURL}/admin/getGallery/${
        slug === 'experiente' ? 'experienta' : slug
      }/images`
    );

    const { data } = answer.data;

    let images = [];

    // eslint-disable-next-line
    data.map((image) => {
      // eslint-disable-next-line
      images.push({
        original: `${GALLERY_PATH}/${image.filename}`,
        thumbnail: `${GALLERY_PATH}/${image.filename}`,
        filename: image.filename,
      });
    });

    setImagesForSlider(images);
  };

  const getGalleryDocs = async (slug) => {
    const answer = await REST.get(`${rURL}/admin/getGallery/${slug}/docs`);

    const { data } = answer.data;

    setDocs(data);
  };

  const getTabs = async (slug) => {
    const answer = await REST.get(`${rURL}/public/${slug}/getAllTabs`);
    setTabs(answer.data.data);
  };

  const loadEntry = async (slug) => {
    const answer = await REST.get(`${rURL}/admin/get/${slug}`);

    const { content, video } = answer.data.data;

    setFormState((formState) => ({
      values: {
        content,
        video,
      },
    }));
  };

  const aboutFormatting = (content) => {
    return {
      __html: content,
    };
  };

  const handleTabOnClick = (id) => {
    window.location = `/sectiune/${slug}/tab/${id}`;
  };

  return (
    <div>
      <div>
        {headerImg && (
          <Grid
            container
            spacing={4}
            className={clsx(classes.gmaps, classes.gigi)}
          >
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
              className={classes.noPadding}
            ></Grid>
          </Grid>
        )}
        <div className={classes.root}></div>
        <div className={classes.testimonialsContent}>
          <Grid container spacing={4} className={classes.testimonialEntry}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <React.Fragment>
                <div className={classes.contentBody}>
                  <Card
                    className={classes.root}
                    style={{
                      minHeight: '300px',
                      paddingTop: '50px',
                      marginBottom: '50px',
                    }}
                  >
                    <CardContent>
                      <div className={classes.titleContainer}>
                        <h2>{title}</h2>
                      </div>

                      <Grid
                        item
                        lg={12}
                        md={12}
                        xl={12}
                        xs={12}
                        className={classes.generalContent}
                      >
                        {formState.values.content && (
                          <>
                            <div className={classes.details}>
                              {imagesForSlider && (
                                <ImageGallery
                                  // ref={i => this._imageGallery = i}
                                  items={imagesForSlider}
                                  lazyLoad={false}
                                  infinite={true}
                                  showBullets={false}
                                  showFullscreenButton={false}
                                  showPlayButton={false}
                                  showThumbnails={true}
                                  showIndex={true}
                                  showNav={true}
                                  isRTL={false}
                                  slideDuration={300}
                                  slideInterval={3000}
                                  additionalClass="app-image-gallery"
                                />
                              )}
                            </div>
                            <div className={classes.contentSectionContainer}>
                              <h3>Prezentare Generala</h3>
                            </div>
                            <div className={classes.contentSectionContainer}>
                              <div
                                dangerouslySetInnerHTML={aboutFormatting(
                                  formState.values.content
                                )}
                                className={classes.formattedHtml}
                              />
                            </div>
                          </>
                        )}
                      </Grid>

                      <br />
                      <br />
                    </CardContent>
                  </Card>

                  {tabs && (
                    <Grid container className={classes.listContainer}>
                      <Grid
                        item
                        container
                        xs={12}
                        md={12}
                        className={classes.tabsContainer}
                      >
                        {tabs.map((item, index) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              className={classes.listCard}
                              key={`listCard--${index}`}
                              onClick={() => handleTabOnClick(item.id)}
                            >
                              <div className={classes.cardImageContainer}>
                                <img
                                  src={`https://www.totaljobs.com/advice/wp-content/uploads/most-common-interview-questions.jpg`}
                                  className={classes.cardImage}
                                  alt={`alt-${index}`}
                                />
                                <p className={classes.tabTitle}>{item.title}</p>
                                {slug === 'practici' && (
                                  <p className={classes.interviewName}>
                                    Interviu cu: {item?.nameOfPerson}
                                  </p>
                                )}

                                {slug === 'ateliere' &&
                                item?.name?.length > 0 ? (
                                  <p className={classes.interviewName}>
                                    {item.name}
                                  </p>
                                ) : null}
                                {slug === 'experiente' &&
                                item?.title?.length > 0 ? (
                                  <p className={classes.interviewName}>
                                    {item.title}
                                  </p>
                                ) : null}
                                <p className={classes.more}>afla mai multe</p>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>

                      {/* {formState?.values?.video && (
                        <Grid container className={classes.listContainer}>
                          <Grid
                            item
                            xs={12}
                            className={classes.listSectionTitle}
                          >
                            <h3>Prezentare Video</h3>
                          </Grid>
                          <br />

                          <iframe
                            title={'video'}
                            style={{ margin: '0 auto', display: 'block' }}
                            width="98%"
                            height="400"
                            src={formState.values.video}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </Grid>
                      )} */}
                    </Grid>
                  )}
                </div>
              </React.Fragment>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default RezultateEntryComponentNew;
