const checked = (value, options) => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
};

// eslint-disable-next-line
export default {
  checked
};
