import { makeStyles } from '@material-ui/styles';
import widgetBg from '../../../Assets/Images/widgetBg2.png';
import PreSlider from '../../../Assets/Images/img_firstscreen@2x.png';
import AfterSlider from '../../../Assets/Images/back2@2x.png';
import AfterSliderLeft from '../../../Assets/Images/img2.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: `1400px`,
    margin: `3rem auto !important`,
    padding: `0 1rem`,
    '@media (min-width: 960px)': {
      position: `relative`,
      top: `112px`,
    },

    '& *': {
      fontFamily: `Arial`,
    },
    '& h1': {
      fontSize: `3rem`,
      maxWidth: `500px`,
      color: `#151b74`,
      margin: `3rem 0 1rem`,
      // textTransform: `capitalize`,
    },

    '& h2': {
      color: `#777`,
      fontWeight: `normal`,
      fontSize: `1.3rem`,
      maxWidth: `450px`,
      margin: `0 0 2rem`,
      // textTransform: `capitalize`,
    },
  },
  noPadding: {
    padding: `0 !important`,
  },
  preSlider: {
    width: `100%`,
    '@media (min-width: 960px)': {
      margin: `-112px 0`,
      backgroundImage: `url(${PreSlider})`,
      backgroundPosition: `top right`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `80%`,
      minHeight: `800px`,
      maxHeight: `1100px`,
      padding: 0 /* reset */,
      // paddingBottom: `calc(100% * 2 / 4)`,
      '@media (min-width: 1300px)': {
        backgroundSize: `auto`,
      },
    },
  },
  afterSlider: {
    // margin: `4rem 0`,
    '@media (min-width: 960px)': {
      height: `400px`,
    },
    '@media (min-width: 1100px)': {
      height: `450px`,
    },
    '@media (min-width: 1200px)': {
      height: `480px`,
    },
    '@media (min-width: 1300px)': {
      height: `500px`,
    },
    '@media (min-width: 1400px)': {
      height: `520px`,
    },
    '@media (min-width: 1500px)': {
      height: `600px`,
    },
    '@media (min-width: 1600px)': {
      height: `650px`,
    },
    '@media (min-width: 1700px)': {
      height: `700px`,
    },
    '@media (min-width: 2000px)': {
      height: `800px`,
    },
    '@media (min-width: 2300px)': {
      height: `900px`,
    },
  },
  afterSliderLeft: {
    width: `100%`,
    '@media (min-width: 960px)': {
      backgroundImage: `url(${AfterSliderLeft})`,
      backgroundPosition: `top right`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `70%`,
    },
  },
  afterSliderRight: {
    width: `100%`,
    '@media (min-width: 960px)': {
      margin: `50px 0 0`,
      backgroundImage: `url(${AfterSlider})`,
      backgroundPosition: `top right`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `100%`,
      height: `100%`,
      width: `50%`,
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
    },
  },
  afterSliderRightContent: {
    position: `relative`,
    maxWidth: `70%`,
    fontSize: `20px`,
    margin: `0 auto`,
    color: `#fff`,
    fontFamily: `Arial`,
    '@media (min-width: 960px)': {
      top: `-50px`,
    },
    '@media (max-width: 959px)': {
      color: `#000`,
    },
    '& h1': {
      fontSize: `1.2em`,
      '@media (min-width: 1200px)': {
        fontSize: `1.5em`,
      },
      '@media (min-width: 1500px)': {
        fontSize: `2.2em`,
      },
    },
    '& h2': {
      fontSize: `.8em`,
      '@media (min-width: 1200px)': {
        fontSize: `.8em`,
      },
      '@media (min-width: 1300px)': {
        fontSize: `1em`,
      },
      fontWeight: `normal`,
      lineHeight: `1.7`,
      margin: `0 0 3rem`,
    },
  },
  experts: {
    margin: '5px 20px 0 0',
  },
  guide: {
    margin: '5px 0 0 0',
  },
  gmaps: {
    textAlign: `center`,
    padding: `0 !important`,
    height: `500px`,
    width: `100%`,
    margin: `0 0 2rem`,
  },
  gmapsWrap: {
    margin: `0`,
    '@media (min-width: 968px)': { margin: `-30px 0 0` },
    position: `relative`,
    '.gmapsLabel': {
      position: `absolute`,
      bottom: `0`,
      left: `0`,
    },
  },
  sliderContainer: {
    fontFamily: `Arial`,
    position: `relative`,
    height: `139px !important`,
    maxHeight: `139px !important`,
    // left: `150px`,
    '& .slick-arrow': {
      display: `none !important`,
    },
    '& .slick-track': {
      maxHeight: `140px`,
      overflow: `hidden`,
      width: `100% !important`,
    },
    '& .slick-slide': {
      maxWidth: `250px !important`,
      margin: `0 25px !important`,
    },
    '& .rec-item-wrapper': {
      width: `275px !important`,
    },
    '& .rec-pagination': {
      display: `none`,
    },
    '& .BrainhubCarouselItem': {
      width: `270px !important`,
      maxWidth: `270px !important`,
      minWidth: `270px !important`,
    },
    // '@media (min-width: 960 & max-width: 1300px)': { top: `-90px` },
    '@media (max-width: 959px)': { top: `-30px` },
    '@media (min-width: 960px)': { top: `-90px` },
    // '@media (min-width: 1501px)': { top: `-100px` },
    // '@media (min-width: 1701px)': { top: `-200px` },
    // '@media (min-width: 1901px)': { top: `-250px` },
    // '@media (min-width: 960px)': { top: `-130px` },
  },
  slideItemWrapper: {
    display: `flex !important`,
    flexFlow: `row nowrap`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    width: `250px !important`,
    height: `139px !important`,
    background: `url(${widgetBg})`,
    color: `#fff`,
  },
  slideItemNumber: {
    position: `relative`,
    left: `10px`,
    textAlign: `center`,
    width: `80px`,
    fontSize: `2rem`,
  },
  slideItemContent: {
    textAlign: `left`,
    width: `170px`,
    fontSize: `0.9rem`,
    lineHeight: `1.7`,
    margin: `0 0 0 2.6rem`,
  },
}));

export default useStyles;
