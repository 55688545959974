import { getHistoryPathArray } from '@Helpers';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { REST, rURL } from '@Utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import validate from 'validate.js';
import AddMediaToEntry from '../MediaLibrary/components/AddMediaToEntry.component';
import schema from './schema';
import TestimonialsAddStyle from './TestimonialsAdd.style';

const AdminTestimonialsAdd = (props) => {
  const classes = TestimonialsAddStyle();
  const { history } = props;

  const [auth, setAuth] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [entryId, setEntryId] = useState(null);
  const [fileFromEdit, setFileFromEdit] = useState(null);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const isEditMode = history.location.pathname.includes('edit');

  useEffect(() => {
    setAuth(true);
    getFileList();
    if (isEditMode) {
      const entryId = getHistoryPathArray(history);
      loadEntry(entryId.slice(-1));
      setEntryId(entryId.slice(-1))
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const stringId = window.location.href.slice(-1)
    const id = parseInt(stringId)
    // parseInt(id)
    setEntryId(id)
  }, [])

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const loadEntry = async (entryId) => {
    const answer = await REST.get(
      `${rURL}/admin/getTestimonialEntry/${entryId}`
    );
    const {
      pic_id_from_media_library,
      name,
      content,
      functie,
    } = answer.data.data;



    setFormState((formState) => ({
      values: {
        pic_id_from_media_library,
        name,
        content,
        functie,
      },
      touched: {
        name: true,
        content: true,
        functie: true,
      },
      isValid: true,
      errors: {},
    }));

    setFileFromEdit(pic_id_from_media_library);
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let url;

    if (formState.isValid) {
      const entryId = getHistoryPathArray(history);
      formState.values.id = entryId.slice(-1).toString();
      if (isEditMode) url = `${rURL}/admin/update-testimonial/`;
      // eslint-disable-next-line
      else url = `${rURL}/admin/add-testimonial`;

      history.push('/admin/testimonials');
    }
  };

  const getFileList = async () => {
    const newFileList = await REST.get(`${rURL}/admin/getFileList`);
    setFileList(newFileList.data.data);
  };

  const onFileSelect = (id, filename) => {
    setFileFromEdit(null);
    formState.values.pic_id_from_media_library = filename;
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;


  const updateTestimonials = async () => {

    if (getHistoryPathArray(history)[3] === "edit") {

      await REST.post(`${rURL}/admin/update-testimonial`, {
        id: entryId,
        pic_id_from_media_library: formState.values.pic_id_from_media_library,
        name: formState.values.name,
        content: formState.values.content,
        functie: formState.values.functie
      })
        .then((result) => {
        })
        .catch(e => {
          console.log("Error: ", e)
        })
    } else if (getHistoryPathArray(history)[3] === "add") {

      await REST.post(`${rURL}/admin/add-testimonial`, {
        pic_id_from_media_library: formState.values.pic_id_from_media_library,
        name: formState.values.name,
        content: formState.values.content,
        functie: formState.values.functie
      })
        .then((result) => {
        })
        .catch(e => {
          console.log("Error: ", e)
        })
    }

  };

  return (
    <div className={classes.root}>
      {auth && (
        <div>
          <Grid container spacing={4}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <h1 className={classes.fontFamily}>Adauga un Testimonial</h1>
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <React.Fragment>
                <div className={classes.contentBody}>
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <AddMediaToEntry
                      fileList={fileList}
                      onFileSelect={onFileSelect}
                      fileFromEdit={fileFromEdit && fileFromEdit}
                    // fileFromEdit={formState && formState.values}
                    />


                    <div>
                      <img alt="" src={fileFromEdit} />
                    </div>


                    <Card className={clsx(classes.root)}>
                      <CardContent>
                        <div className={classes.details}>
                          <div>
                            <Typography gutterBottom variant="h2">
                              Nume si continut
                            </Typography>
                          </div>
                        </div>

                        <TextField
                          className={classes.textField}
                          error={hasError('name')}
                          fullWidth
                          helperText={
                            hasError('name') ? formState.errors.name[0] : null
                          }
                          label={'Numele persoanei'}
                          name="name"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.name || ''}
                          variant="outlined"
                        />

                        <TextField
                          className={classes.textField}
                          // error={hasError('functie')}
                          fullWidth
                          // helperText={
                          //   hasError('functie')
                          //     ? formState.errors.functie[0]
                          //     : null
                          // }
                          label={'Functie'}
                          name="functie"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.functie || ''}
                          variant="outlined"
                        />

                        <TextField
                          multiline
                          rows={10}
                          aria-multiline={true}
                          className={classes.textField}
                          error={hasError('content')}
                          fullWidth
                          helperText={
                            hasError('content')
                              ? formState.errors.content[0]
                              : null
                          }
                          label="Continut"
                          name="content"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.content || ''}
                          variant="outlined"
                        />
                      </CardContent>
                      <Divider />
                      <CardActions>
                        <Button
                          className={classes.signInButton}
                          color="primary"
                          disabled={!formState.isValid}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          to={'media'}
                          onClick={updateTestimonials}
                        >
                          Save entry
                        </Button>
                      </CardActions>
                    </Card>
                  </form>
                </div>
              </React.Fragment>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default AdminTestimonialsAdd;
