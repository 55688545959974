import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AteliereLucru from './AteliereLucru';
import BunePractici from './BunePractici';
import SchimbExperienta from './SchimbExperienta';
import AddModal from './Components/AddModal';
import Modal from '@material-ui/core/Modal';
import { Grid } from '@material-ui/core';
import { REST, rURL } from '../../../Utils/index';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    overflowY: 'hidden',
  },

  modal: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(3px)',
  },

  modalBody: {
    width: '70vw',
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // overflowX: 'hidden',
    overflowY: 'auto',
  },

  tabsContainer: {
    width: '100%',
  },

  cardImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 10,
    marginTop: 10,
    paddingBottom: 5,
    border: '1px solid #d3d3d3',
  },

  cardImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    objectPosition: 'center',
  },

  titleContainer: {
    marginLeft: '20px',
    '& h2': {
      color: `#77b3d4`,
      fontSize: `30px`,
    },
  },

  interviewName: {
    color: '#0377fc',
    margin: 10,
  },

  more: {
    color: '#0377fc',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px',
  },

  btn: {
    cursor: 'pointer',
    marginLeft: 25,
  },

  listContainer: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    overflowY: 'hidden',
  },
}));

var btnLabel,
  title,
  slug = 'ateliere';

export default function RezultateTabs() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editInfo, setEditInfo] = useState([]);
  const [tabs, setTabs] = useState([]);

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        btnLabel = 'Adauga etapa';
        title = ' etapa';
        slug = 'ateliere';
        getTabs('ateliere');
        break;
      case 1:
        btnLabel = 'Adauga interviuri';
        title = 'Adaugati interviuri';
        slug = 'practici';
        getTabs('practici');
        break;
      case 2:
        btnLabel = 'Adauga povesti';
        title = 'Adaugati povesti';
        slug = 'experiente';
        getTabs('experiente');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    btnLabel = 'Adauga etapa';
    title = ' etapa';
    getTabs(slug);
  }, []);

  const handleModel = () => {
    setIsVisible(!isVisible);
  };

  const getTabs = async (slug) => {
    const answer = await REST.get(`${rURL}/public/${slug}/getAllTabs`);
    setTabs(answer.data.data);
  };

  const handleDelete = async (id) => {
    switch (slug) {
      case 'ateliere':
        await REST.post(`${rURL}/admin/${slug}/deleteTab/${id}`);
        break;
      case 'practici':
        await REST.post(`${rURL}/admin/${slug}/deleteTab/${id}`);
        break;
      case 'experiente':
        await REST.post(`${rURL}/admin/${slug}/deleteTab/${id}`);
        break;
      default:
        break;
    }
    getTabs(slug);
  };

  const handleEdit = (item) => {
    setEditInfo(item);
    setEdit(!edit);
    setIsVisible(!isVisible);
  };

  const handleCloseEditModal = () => {
    setEditInfo(null);
    setEdit(false);
    setIsVisible(false);
    localStorage.clear();
    getTabs(slug);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Ateliere de lucru" {...a11yProps(0)} />
            <Tab label="Bune practici" {...a11yProps(1)} />
            <Tab label="Schimb de experienta" {...a11yProps(2)} />
          </Tabs>
        </Paper>
      </AppBar>
      <TabPanel value={value} index={0}>
        <AteliereLucru btnLabel={btnLabel} handleModal={handleModel} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BunePractici btnLabel={btnLabel} handleModal={handleModel} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SchimbExperienta btnLabel={btnLabel} handleModal={handleModel} />
      </TabPanel>

      {tabs && (
        <Grid container className={classes.listContainer}>
          <h2>
            {slug === 'ateliere' && 'Etape adaugate'}
            {slug === 'practici' && 'Interviuri adaugate'}
            {slug === 'experiente' && 'Povesti adaugate'}
          </h2>
          <Grid
            item
            container
            xs={12}
            md={12}
            className={classes.tabsContainer}
          >
            {tabs.map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className={classes.listCard}
                  key={`listCard--${index}`}
                  // onClick={() => handleTabOnClick(item.id)}
                >
                  <div className={classes.cardImageContainer}>
                    <img
                      src={`https://www.totaljobs.com/advice/wp-content/uploads/most-common-interview-questions.jpg`}
                      className={classes.cardImage}
                      alt={`alt-${index}`}
                    />

                    {slug === 'practici' && (
                      <div>
                        <p className={classes.interviewName}>{item.title}</p>
                        <p className={classes.interviewName}>
                          Interviu cu: {item?.nameOfPerson}
                        </p>
                      </div>
                    )}

                    {slug === 'ateliere' && item?.name?.length > 0 && (
                      <p className={classes.interviewName}>{item.name}</p>
                    )}
                    {slug === 'experiente' && item?.title?.length > 0 && (
                      <p className={classes.interviewName}>{item.title}</p>
                    )}
                    <div className={classes.more}>
                      <IconButton aria-label="delete" className={classes.btn}>
                        <EditIcon onClick={() => handleEdit(item)} />
                      </IconButton>

                      <IconButton aria-label="delete" className={classes.btn}>
                        <DeleteIcon
                          onClick={() => {
                            if (
                              window.confirm(
                                'Sunteti sigur ca doriti sa stergeti interviul?'
                              )
                            ) {
                              handleDelete(item.id);
                            } else {
                              console.log('NU!');
                            }
                          }}
                        />
                      </IconButton>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}

      <Modal
        open={isVisible}
        onBackdropClick={handleCloseEditModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <div className={classes.modalBody}>
          <AddModal
            title={title}
            slug={slug}
            setIsVisible={setIsVisible}
            item={editInfo}
            edit={edit}
            handleCloseEditModal={handleCloseEditModal}
          />
        </div>
      </Modal>
    </div>
  );
}
