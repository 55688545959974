import { makeStyles } from '@material-ui/styles';
import { MEDIA_PATH } from '../../../Utils';

const RezultateStyle = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    height: '100%',
    padding: theme.spacing(4),
    width: `100%`,
  },
  grid: {
    height: '100%',
  },
  quote: {
    // backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    // flexGrow: 1,
    // display: 'flex',
    alignItems: 'center',
    // [theme.breakpoints.down('md')]: {
    //   justifyContent: 'center',
    // },
  },

  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },

  dropzone: {
    border: '2px solid #e3e3e3',
    borderRadius: 10,
    textAlign: 'center',
    // fontFamily: 'Arial',
    margin: `0 0 20px`,
    fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif`,
  },

  dropParagraph: {
    padding: '40px',
  },

  fontFamily: {
    fontFamily: 'Arial',
  },

  // galleryWrapper: {
  //   width: '100%',
  //   height: '180px',
  //   margin: '0 0 2rem 0',
  //   whiteSpace: 'nowrap',
  //   position: 'relative',
  //   overflowX: 'scroll',
  //   overflowY: 'hidden',
  // },

  // galleryItemPic: {
  //   width: '24.5%',
  //   backgroundColor: '#eee',
  //   float: 'none',
  //   height: '150px',
  //   margin: '0 0.25%',
  //   display: 'inline-block',
  //   zoom: 1,

  //   '& img': {
  //     maxHeight: '70%',
  //     margin: '.5rem auto',
  //     display: 'block',
  //   },
  // },

  deleteButton: {
    margin: '0 auto',
    display: 'block',
    width: '40px',
    textAlign: 'center',
  },

  noPadding: {
    padding: `0 !important`,
  },
  formatted: {
    maxWidth: `1400px`,
    // margin: `3rem auto !important`,
    width: `90%`,
    lineHeight: 2,
    '& *': {
      fontFamily: `Arial`,
    },
    '& h1': {
      fontSize: `2rem`,
      color: `#151b74`,
      margin: `2rem 0`,
      textTransform: `uppercase`,
    },
    '& ul': { padding: `0 20px` },

    '& p': {
      lineHeight: 1.7,
      margin: `1.7rem 0`,
    },
  },

  gmaps: (props) => ({
    backgroundImage: `url(${MEDIA_PATH}/${props.headerImg}`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    textAlign: `center`,
    padding: `0 !important`,
    height: `500px`,
    width: `100%`,
    margin: `0 0 2rem`,
  }),
  gigi: {
    backgroundPosition: `center center`,
  },
  gmapsWrap: {
    margin: `0`,
    position: `relative`,
    '.gmapsLabel': {
      position: `absolute`,
      bottom: `0`,
      left: `0`,
    },
  },
  testimonialsContent: {
    width: `90%`,
    maxWidth: `1400px`,
    margin: `3rem auto !important`,
    '& *': {
      fontFamily: `Arial`,
    },
    '& h1': {
      fontSize: `2rem`,
      color: `#151b74`,
      margin: `2rem 0`,
      textTransform: `uppercase`,
    },
    '& h2': {
      textTransform: `capitalize`,
      fontSize: `2rem`,
    },
    position: `relative`,
  },

  testimonialitem: {
    padding: 0,
  },

  testimonialAvatar: {
    '& img': {
      borderRadius: `100px`,
      maxWidth: `200px`,
      margin: `0 auto`,
      textAlign: `center`,
      display: `block`,
    },
  },
  testimonialWrap: {
    position: `relative`,
    left: `2rem`,
  },
  testimonialEntry: {
    width: `100%`,
    '@media (min-width: 960px)': {
      margin: `0 0 6rem`,
    },
    '@media (max-width: 959px)': {
      margin: `0 0 1rem`,
    },
  },
  testimonialText: {
    maxWidth: `91%`,
    fontSize: `1.4rem`,
    lineHeight: `1.7`,
  },
  testimonialName: {
    // '@media (min-width: 960px)': {
    //   position: `absolute`,
    //   bottom: 0,
    // },

    // '@media (max-width: 959px)': {
    margin: `1.5rem 0`,
    // },

    color: `#151b74 !important`,
    fontSize: `1.4rem`,
  },
  galleryWrapper: {
    width: '100%',
    margin: '0 0 2rem 0',
    '@media (max-width: 959px)': {
      width: '100%',
      display: 'block',
    },
    '@media (min-width: 960px)': {
      height: '180px',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      overflowY: 'hidden',
    },
  },

  galleryItemPic: {
    '@media (max-width: 959px)': {
      width: '100%',
      display: 'block',
      margin: '0 0 10px 0',
    },
    '@media (min-width: 960px)': {
      width: '33.333%',
      display: 'inline-block',
    },

    backgroundColor: '#eee',
    float: 'none',
    height: '150px',
    padding: '1rem',
    margin: '0 0.25%',
    zoom: 1,

    '& img': {
      maxHeight: '70%',
      margin: '.5rem auto',
      display: 'block',
    },
  },
}));

export default RezultateStyle;
