import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { REST, rURL } from '../../../Utils/index';
import ContactIndex from './ContactIndex';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },

  fontFamily: {
    fontFamily: 'Arial',
  },
}));

const AdminContact = () => {
  const classes = useStyles();

  const [auth, setAuth] = useState(false);
  const [entriesList, setEntriesList] = useState([]);

  useEffect(() => {
    setAuth(true);
    getMessages();
    // eslint-disable-next-line
  }, []);

  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
    } else didMountRef.current = true;
  });

  const getMessages = async () => {
    const newentriesList = await REST.get(`${rURL}/admin/getMessages`);
    setEntriesList(newentriesList.data.data);
  };

  const onDelete = async (id) => {
    // eslint-disable-next-line
    const result = await REST.post(`${rURL}/admin/delete-contact-message`, {
      id,
    });
    getMessages();
  };

  // eslint-disable-next-line
  const onFilter = (rows) => {
    if (rows.data.length > 0) {
      setEntriesList(rows.data);
    }
  };


  return (
    <div className={classes.root}>
      {auth && entriesList.length > 0 && (
        <div className={classes.content}>
          <ContactIndex entries={entriesList} onDelete={onDelete} />
        </div>
      )}
    </div>
  );
};

export default AdminContact;
