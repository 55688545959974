import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { REST, rURL } from '../../../Utils/index';
import HeadpicsIndex from './headpicsIndex.component';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },

  dropzone: {
    border: '2px solid #e3e3e3',
    borderRadius: 10,
    textAlign: 'center',
    // fontFamily: 'Arial',
    margin: `0 0 20px`,
    fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif`,
  },

  dropParagraph: {
    padding: '40px',
  },

  fontFamily: {
    fontFamily: 'Arial',
  },
}));

const AdminHeadpicsIndex = () => {
  const classes = useStyles();

  const [auth, setAuth] = useState(false);
  const [entriesList, setEntriesList] = useState([]);

  useEffect(() => {
    setAuth(true);
    getHeadpicsList();
   // eslint-disable-next-line
  }, []);

  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      // console.log(entriesList);
    } else didMountRef.current = true;
  });

  const getHeadpicsList = async () => {
    const newentriesList = await REST.get(`${rURL}/admin/getHeadpics`);
    setEntriesList(newentriesList.data.data);
  };

  return (
    <div className={classes.root}>
      {auth && entriesList.length > 0 && (
        <div className={classes.content}>
          <HeadpicsIndex entries={entriesList} />
        </div>
      )}
    </div>
  );
};

export default AdminHeadpicsIndex;
