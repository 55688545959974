import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './Components';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Website as WebsiteLayout,
  NoLayout,
} from './Layouts';

import {
  Homepage as HomepageView,
  About as AboutView,
  Institutions as InstitutionsView,
  InstitutionEntry as InstitutionEntryView,
  Experts as ExpertsView,
  // RezultateEntryComponent,
  RezultateEntryComponentNew,
  EntryTabPage,
  Contact as ContactView,
  MediaLibrary as MediaLibraryView,
  AdminAbout as AdminAboutView,
  AdminContact as AdminContactView,
  AdminInstitutions as AdminInstitutionsView,
  AdminInstitutionsAdd as AdminInstitutionsAddView,
  AdminTestimonials as AdminTestimonialsView,
  AdminTestimonialsAdd as AdminTestimonialsAddView,
  AdminExperts as AdminExpertsView,
  AdminRezultate as RezultateView,
  AdminExpertsAdd as AdminExpertsAddView,
  AdminHomepageWidgets as AdminHomepageWidgetsView,
  AdminHomepageWidgetsAdd as AdminHomepageWidgetsAddView,
  AdminHomepageTitle as AdminHomepageTitleView,
  AdminHomepageIntermezzo as AdminHomepageIntermezzoView,
  AdminHeadpicsIndex as AdminHeadpicsIndexView,
  AdminHeadpicsEntry as AdminHeadpicsEntryView,
  IframeMap as IframeMapVew,
  Settings as SettingsView,
  SignIn as SignInView,
  NotFound as NotFoundView,
} from './Views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/admin" to="/admin/institutions" />

      {/* ===== PUBLIC ===== */}
      <RouteWithLayout
        component={HomepageView}
        exact
        layout={WebsiteLayout}
        path="/"
      />

      <RouteWithLayout
        component={AboutView}
        exact
        layout={WebsiteLayout}
        path="/despre-proiect"
      />

      <RouteWithLayout
        component={InstitutionsView}
        exact
        layout={WebsiteLayout}
        path="/institutii"
      />

      <RouteWithLayout
        component={InstitutionEntryView}
        exact
        layout={WebsiteLayout}
        path="/institutii/:entry"
      />

      <RouteWithLayout
        component={ExpertsView}
        exact
        layout={WebsiteLayout}
        path="/rezultate"
      />

      {/* <RouteWithLayout
        component={RezultateEntryComponent}
        exact
        layout={WebsiteLayout}
        path="/rezultate/ateliere-de-lucru"
      /> */}

      <RouteWithLayout
        component={RezultateEntryComponentNew}
        exact
        layout={WebsiteLayout}
        path="/rezultate/ateliere-de-lucru"
      />

      <RouteWithLayout
        component={EntryTabPage}
        exact
        layout={WebsiteLayout}
        path="/sectiune/:slug/tab/:tabId"
      />

      {/* <RouteWithLayout
        component={RezultateEntryComponent}
        exact
        layout={WebsiteLayout}
        path="/rezultate/bune-practici"
      /> */}

      <RouteWithLayout
        component={RezultateEntryComponentNew}
        exact
        layout={WebsiteLayout}
        path="/rezultate/bune-practici"
      />

      {/* <RouteWithLayout
        component={RezultateEntryComponent}
        exact
        layout={WebsiteLayout}
        path="/rezultate/schimb-de-experienta"
      /> */}

      <RouteWithLayout
        component={RezultateEntryComponentNew}
        exact
        layout={WebsiteLayout}
        path="/rezultate/schimb-de-experienta"
      />

      <RouteWithLayout
        component={EntryTabPage}
        exact
        layout={WebsiteLayout}
        path="/tab"
      />

      <RouteWithLayout
        component={ContactView}
        exact
        layout={WebsiteLayout}
        path="/contact"
      />

      {/* ===== ADMIN ===== */}

      <RouteWithLayout
        component={MediaLibraryView}
        exact
        layout={MainLayout}
        path="/admin/media"
      />

      {/* ===== INSTITUTIONS ===== */}

      <RouteWithLayout
        component={AdminInstitutionsView}
        exact
        layout={MainLayout}
        path="/admin/institutions"
      />

      <RouteWithLayout
        component={AdminInstitutionsAddView}
        exact
        layout={MainLayout}
        path="/admin/institutions/add"
      />

      <RouteWithLayout
        component={AdminInstitutionsAddView}
        exact
        layout={MainLayout}
        path="/admin/institutions/edit/:entry_id"
      />

      {/* ====== TESTIMONIALS ======= */}
      <RouteWithLayout
        component={AdminTestimonialsView}
        exact
        layout={MainLayout}
        path="/admin/testimonials"
      />

      <RouteWithLayout
        component={AdminTestimonialsAddView}
        exact
        layout={MainLayout}
        path="/admin/testimonials/add"
      />

      <RouteWithLayout
        component={AdminTestimonialsAddView}
        exact
        layout={MainLayout}
        path="/admin/testimonials/edit/:entry_id"
      />

      {/* ====== EXPERTS ======= */}
      <RouteWithLayout
        component={AdminExpertsView}
        exact
        layout={MainLayout}
        path="/admin/procese-achizitii"
      />

      <RouteWithLayout
        component={AdminExpertsAddView}
        exact
        layout={MainLayout}
        path="/admin/procese-achizitii/add"
      />

      <RouteWithLayout
        component={AdminExpertsAddView}
        exact
        layout={MainLayout}
        path="/admin/procese-achizitii/edit/:entry_id"
      />

      <RouteWithLayout
        component={RezultateView}
        exact
        layout={MainLayout}
        path="/admin/rezultate"
      />

      {/* ====== CONTACT ======= */}
      <RouteWithLayout
        component={AdminContactView}
        exact
        layout={MainLayout}
        path="/admin/contact"
      />

      {/* ====== ABOUT ======= */}
      <RouteWithLayout
        component={AdminAboutView}
        exact
        layout={MainLayout}
        path="/admin/about"
      />

      {/* ===== WIDGETS ======= */}
      <RouteWithLayout
        component={AdminHomepageWidgetsView}
        exact
        layout={MainLayout}
        path="/admin/homepage-widgets"
      />

      <RouteWithLayout
        component={AdminHomepageWidgetsAddView}
        exact
        layout={MainLayout}
        path="/admin/homepage-widgets/add"
      />

      <RouteWithLayout
        component={AdminHomepageWidgetsAddView}
        exact
        layout={MainLayout}
        path="/admin/homepage-widgets/edit/:entry_id"
      />

      {/* ====== HOMEPAGE TITLES ======= */}
      <RouteWithLayout
        component={AdminHomepageTitleView}
        exact
        layout={MainLayout}
        path="/admin/homepage-title"
      />

      <RouteWithLayout
        component={AdminHomepageIntermezzoView}
        exact
        layout={MainLayout}
        path="/admin/homepage-intermezzo"
      />

      {/* ====== HEADPICS ======= */}
      <RouteWithLayout
        component={AdminHeadpicsIndexView}
        exact
        layout={MainLayout}
        path="/admin/headpics"
      />

      <RouteWithLayout
        component={AdminHeadpicsEntryView}
        exact
        layout={MainLayout}
        path="/admin/headpics/edit/:entry"
      />

      {/* ===== WEBSITE ===== */}

      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />

      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/login"
      />

      <RouteWithLayout
        component={IframeMapVew}
        exact
        layout={NoLayout}
        path="/iframe-map"
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={NoLayout}
        path="/404"
      />
      <Redirect to="/404" />
    </Switch>
  );
};

export default Routes;
