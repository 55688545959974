// MATERIAL UI
import { Card, CardContent, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// COMPONENTS
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { useParams } from 'react-router-dom';
// UTILS
import { GALLERY_PATH, REST, rURL } from '../../../Utils/index';
// STYLE
import RezultateStyles from './rezultate.style-new';

const EntryTabPage = (props) => {
  const { tabId = null, slug = null } = useParams();
  const [headerImg, setHeaderImg] = useState(null);

  const classes = RezultateStyles({ headerImg });

  const [imagesForSlider, setImagesForSlider] = useState(null);
  const [docs, setDocs] = useState(null);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const loadHeaderImage = async () => {
    const answer = await REST.get(`${rURL}/public/getHeadpic/3`);
    const { pic_id_from_media_library } = answer.data.data;
    setHeaderImg(pic_id_from_media_library);
  };

  useEffect(() => {
    loadEntry();
    getGalleryImages();
    getGalleryDocs();

    loadHeaderImage();
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const getGalleryImages = async () => {
    // const answer = await REST.get(
    //   `${rURL}/admin/getGallery/${slug === 'experiente' ? 'experienta' : slug
    //   }/images`
    // );
    const answer = await REST.get(
      `${rURL}/admin/getGallery/${slug}_${tabId}/images`
    );

    const { data } = answer.data;

    let images = [];

    // eslint-disable-next-line
    data.map((image) => {
      // eslint-disable-next-line
      images.push({
        original: `${GALLERY_PATH}/${image.filename}`,
        thumbnail: `${GALLERY_PATH}/${image.filename}`,
        filename: image.filename,
      });
    });

    setImagesForSlider(images);
  };
  // eslint-disable-next-line
  const getGalleryDocs = async () => {
    const answer = await REST.get(
      `${rURL}/admin/getGallery/${slug}_${tabId}/docs`
    );

    const { data } = answer.data;

    setDocs(data);
  };

  const loadEntry = async () => {
    const answer = await REST.get(`${rURL}/public/${slug}/getTabById/${tabId}`);

    const {
      title,
      nameOfPerson,
      institutionName,
      shortDescription,
      institutionLink,
      content,
      video,
      previewImage,
      conclusion,
    } = answer.data.data[0];

    setFormState((formState) => ({
      values: {
        content: content || null,
        video: video || null,
        shortDescription: shortDescription || null,
        title: title || null,
        institutionLink: institutionLink || null,
        nameOfPerson: nameOfPerson || null,
        previewImage: previewImage || null,
        institutionName: institutionName || null,
        conclusion: conclusion || null,
      },
    }));
  };

  const aboutFormatting = (content) => {
    return {
      __html: content,
    };
  };


  return (
    <div>
      <div className={classes.root}>
        <div className={classes.heroPng}></div>
        <div className={classes.testimonialsContent}>
          <Grid container spacing={4} className={classes.testimonialEntry}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <React.Fragment>
                <div className={classes.contentBody}>
                  <Card className={classes.root}>
                    <CardContent className={classes.cardContainer}>
                      <div>
                        <div>
                          {formState && (
                            <h2>
                              <Grid
                                item
                                xs={12}
                                className={classes.listSectionTitle}
                              >
                                <h3> {formState?.values?.title}</h3>
                              </Grid>
                            </h2>
                          )}
                          <br />
                        </div>
                      </div>
                      <Grid
                        container
                        className={classes.contentContainer}
                        spacing={2}
                      >
                        {formState.values.content && (
                          <Grid
                            item
                            lg={7}
                            md={7}
                            xl={8}
                            xs={12}
                            className={classes.rightSectionContentContainer}
                          >
                            <>
                              <div className={classes.contentSectionContainer}>
                                {slug === 'practici' && (
                                  <h4>
                                    {formState?.values?.institutionName} (
                                    <a
                                      href={`http://185.181.8.20:8080/institutii/${formState?.values?.institutionLink}`}
                                    >
                                      Link catre institutie
                                    </a>
                                    )
                                  </h4>
                                )}
                                {slug === 'practici' && (
                                  <h4 style={{ marginTop: '30px' }}>
                                    Nume intervievat:{' '}
                                    {formState?.values?.nameOfPerson}
                                  </h4>
                                )}
                              </div>

                              <div className={classes.contentSectionContainer}>
                                <div
                                  dangerouslySetInnerHTML={aboutFormatting(
                                    formState.values.content
                                  )}
                                  className={classes.formattedHtml}
                                />
                              </div>
                            </>


                            {slug === 'experiente' && (
                              <div>
                                {/* <Grid
                                  item
                                  xs={12}
                                  className={classes.listSectionTitle}
                                  style={{ marginTop: '50px' }}
                                >
                                  <h3>De reținut</h3>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  className={classes.contentSectionContainer}
                                >
                                  {formState?.values?.conclusion}
                                </Grid> */}
                              </div>
                            )}

                            {imagesForSlider && imagesForSlider.length > 0 && (
                              <div className={classes.details}>
                                <Grid
                                  item
                                  xs={12}
                                  className={classes.listSectionTitle}
                                >
                                  <h3>Galerie foto</h3>
                                </Grid>
                                <br />
                                <ImageGallery
                                  items={imagesForSlider}
                                  lazyLoad={false}
                                  infinite={true}
                                  showBullets={false}
                                  showFullscreenButton={false}
                                  showPlayButton={false}
                                  showThumbnails={true}
                                  showIndex={true}
                                  showNav={true}
                                  isRTL={false}
                                  slideDuration={300}
                                  slideInterval={3000}
                                  additionalClass="app-image-gallery"
                                  className={classes.imageSliderEntryTab}
                                />
                              </div>
                            )}

                            <br />

                            {formState?.values?.video && (
                              <Grid container className={classes.listContainer}>
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    className={classes.listSectionTitle}
                                  >
                                    <h3>Video</h3>
                                  </Grid>
                                  <br />

                                  <iframe
                                    title={'YouTube video player'}
                                    style={{
                                      margin: '0 auto',
                                      display: 'block',
                                    }}
                                    width="98%"
                                    height="400"
                                    src={formState?.values?.video}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                </>
                              </Grid>
                            )}
                          </Grid>
                        )}
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={3}
                          xs={12}
                          className={classes.leftSectionContentContainer}
                        >
                          <div className={classes.documentsContainer}>
                            <h3>De reținut</h3>
                            <div>
                              <br />
                              {formState?.values?.conclusion || ''}
                            </div>
                          </div>

                          <div className={classes.documentsContainer}>
                            <h3>Documente</h3>
                            <div>
                              <br />
                              {docs && docs.length > 0 ? (
                                <div className={classes.galleryWrapperNew}>
                                  {docs.map((docItem, index) => {
                                    return (
                                      <div
                                        className={classes.galleryItemPicNew}
                                        key={`document--${index}`}
                                      >
                                        <a
                                          href={`${GALLERY_PATH}/${docItem.filename}`}
                                        >
                                          <div
                                            style={{
                                              textOverflow: 'ellipsis',
                                              textAlign: 'start',
                                              overflow: 'hidden',
                                              color: '#4b81a5',
                                            }}
                                          >
                                            {docItem.orig_name}
                                          </div>
                                        </a>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <br />
                      <br />
                    </CardContent>
                  </Card>
                </div>
              </React.Fragment>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default EntryTabPage;
