import axios from 'axios';
import { rURL } from '../Utils';
import authHeader from './authHeader';

class UserService {
  getPublicContent() {
    return axios.get(`${rURL}/all`);
  }

  hasToken() {
    return axios.get(`${rURL}/admin/hasToken`, { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(`${rURL}/mod`, { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(`${rURL}/admin`, { headers: authHeader() });
  }
}

export default new UserService();
