import {
  AppBar,
  Button,
  ClickAwayListener,
  Grow, Hidden,
  IconButton,
  List,
  ListItem, MenuItem, Paper, Popper, Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// import { DropDownMenu, AppBar } from '@material-ui';
import LogoImg from '../../../../Assets/Images/logo_160.png';
import HeaderImg from '../../../../Assets/Images/img_header.PNG'


const useStyles = makeStyles(() => ({
  root: {
    background: `#fff`,
    boxShadow: 'none',
    fontFamily: `Arial`,
    // textTransform: `uppercase`,
    '& a, a:hover, a:active, a:visited': {
      outline: `none`,
      color: '#151b74',
      // padding: `0 0 .3rem 0`,
    },
  },
  logo: {
    maxWidth: `120px`,
    margin: `1rem 0 0`,
  },
  flexGrow: {
    flexGrow: 1,
    display: 'flex',
  },
  flexGrow2: {
    flexGrow: 0.2,
    display: 'flex',
    textAlign: `center`,
    '& a': {
      textAlign: `center`,
      display: `block`,
      width: `100%`,
    },
  },
  item: {
    '& a, a:hover, a:active, a:visited': {
      outline: `none`,
      color: '#151b74',
      padding: `0 0 .5rem 0`,
    },
    '& a:hover': {
      borderBottom: `1px solid #151b74`,
    },
  },

  liCenterText: {
    textAlign: 'center',
  },

  btnDropdown: {
    outline: `none`,
    color: '#151b74',
    padding: `0 0 .5rem 0`,
    fontFamily: 'Arial',
    fontSize: '16px',
    background: 'none',
    backgroundColor: 'transparent !important',
    textTransform: "capitalize",
    textAlign: 'center',

    '& a:hover': {
      borderBottom: `1px solid #151b74`,
    },
  },

  dropdown: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    // top: '3px',

    '& .MuiPaper-root': {
      maxWidth: '300px',

      '& li': {
        display: 'block',
        width: '100%',
      },
    },
  },
  header:{
    width: '100%',
  }
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  // eslint-disable-next-line
  const [dropdownSelection, setDropdownSelection] = useState(null);

  const classes = useStyles();
  // eslint-disable-next-line
  const handleDropdownSelection = (event, index, value) => {
    window.location.href = `./${value}`;
  };

  const pages = [
    {
      title: 'Despre proiect',
      href: '/despre-proiect',
    },
    {
      title: 'Autorități contractante',
      href: '/institutii',
    },
    {
      title: 'Rezultate',
      href: '/rezultate',
    },
    {
      title: 'Contact',
      href: '/contact',
    },
  ];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, redirectTo) => {
    if (redirectTo) window.location.href = redirectTo;
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      // color="white"
      position="fixed"
    >
      <h1>
        <img  src={HeaderImg} alt='' className={classes.header}/>
      </h1>
      <Toolbar>
        <RouterLink to="/">
          <h1>
            <img className={classes.logo} src={LogoImg} alt={`Logo`} />
          </h1>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon color={`secondary`} />
          </IconButton>
        </Hidden>

        <Hidden smDown>
          <List className={classes.flexGrow2}>
            {pages.map((page) => (
              <ListItem
                className={clsx(classes.item, classes.liCenterText)}
                disableGutters
                key={page.title}
              >
                {page.title === 'Rezultate' && (
                  <div className={classes.dropdown}>
                    <Button
                      ref={anchorRef}
                      aria-controls={open ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                      className={classes.btnDropdown}
                      disableRipple={true}
                    >
                      <span>Rezultate</span>
                    </Button>
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom'
                                ? 'center top'
                                : 'center bottom',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuItem
                                autoFocusItem={open}
                                id="menu-list-grow"
                                onKeyDown={handleListKeyDown}
                              >
                                <MenuItem
                                  onClick={(e) =>
                                    handleClose(e, '/rezultate/ateliere-de-lucru')
                                  }
                                >
                                  Ateliere de lucru
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) =>
                                    handleClose(e, '/rezultate/bune-practici')
                                  }
                                >
                                  Bune practici
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) =>
                                    handleClose(e, '/rezultate/schimb-de-experienta')
                                  }
                                >
                                  Schimb de experiență
                                </MenuItem>
                              </MenuItem>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                )}
                {page.title !== 'Rezultate' && (
                  <a
                    // activeClassName={classes.active}
                    className={classes.button}
                    // component={CustomRouterLink}
                    href={page.href}
                  >
                    {page.title}
                  </a>
                )}
              </ListItem>
            ))}
          </List>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
