import {
  Button,
  Card,
  CardActions, Divider,
  Grid
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import validate from 'validate.js';
import { getHistoryPathArray } from '../../../Helpers';
import { REST, rURL } from '../../../Utils/index';
import AddMediaToEntry from '../MediaLibrary/components/AddMediaToEntry.component';
import HeadPicsStyle from './HeadPics.style';
import schema from './schema';

const AdminHeadpicsEntry = (props) => {
  const classes = HeadPicsStyle();
  const { history } = props;

  const [auth, setAuth] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileFromEdit, setFileFromEdit] = useState(null);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const isEditMode = history.location.pathname.includes('edit');

  useEffect(() => {
    setAuth(true);
    getFileList();
    if (isEditMode) {
      const entryId = getHistoryPathArray(history);
      loadEntry(entryId.slice(-1));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const loadEntry = async (entryId) => {
    const answer = await REST.get(`${rURL}/admin/getHeadpic/${entryId}`);
    const { pic_id_from_media_library, title } = answer.data.data;

    setFormState((formState) => ({
      values: {
        pic_id_from_media_library,
        title,
      },
      touched: {
        pic_id_from_media_library: true,
      },
      isValid: true,
      errors: {},
    }));

    setFileFromEdit(pic_id_from_media_library);
  };

  // eslint-disable-next-line
  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let url;

    if (formState.values.pic_id_from_media_library) {
      const entryId = getHistoryPathArray(history);
      formState.values.id = entryId.slice(-1).toString();
      if (isEditMode) url = `${rURL}/admin/update-headpic/`;

      // eslint-disable-next-line
      const result = await REST.post(url, formState.values);

      history.push('/admin/headpics');
    }
  };

  const getFileList = async () => {
    const newFileList = await REST.get(`${rURL}/admin/getFileList`);
    setFileList(newFileList.data.data);
  };

  const onFileSelect = (id, filename) => {
    setFileFromEdit(null);
    formState.values.pic_id_from_media_library = filename;
  };
  // eslint-disable-next-line
  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      {auth && (
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <h1 className={classes.fontFamily}>
              Imagine pagina {formState.values.title}
            </h1>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <React.Fragment>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <AddMediaToEntry
                    fileList={fileList}
                    onFileSelect={onFileSelect}
                    fileFromEdit={fileFromEdit}
                  />

                  <Card className={clsx(classes.root)}>
                    <Divider />
                    <CardActions>
                      <Button
                        className={classes.signInButton}
                        color="primary"
                        // disabled={!formState.values.pic_id_from_media_library}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        to={'media'}
                      >
                        Save entry
                      </Button>
                    </CardActions>
                  </Card>
                </form>
              </div>
            </React.Fragment>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AdminHeadpicsEntry;
