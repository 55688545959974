import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import { makeStyles } from '@material-ui/styles';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import validate from 'validate.js';
import { GALLERY_PATH, REST, rURL } from '../../../../Utils/index';
import Uploader from '../Uploader';
import schema from './schema';
import AddButton from '../Components/AddButton';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    height: '100%',
    padding: theme.spacing(4),
    width: `100%`,
  },
  grid: {
    height: '100%',
  },
  quote: {
    // backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    // flexGrow: 1,
    // display: 'flex',
    alignItems: 'center',
    // [theme.breakpoints.down('md')]: {
    //   justifyContent: 'center',
    // },
  },

  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },

  dropzone: {
    border: '2px solid #e3e3e3',
    borderRadius: 10,
    textAlign: 'center',
    // fontFamily: 'Arial',
    margin: `0 0 20px`,
    fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif`,
  },

  dropParagraph: {
    padding: '40px',
  },

  fontFamily: {
    fontFamily: 'Arial',
  },

  galleryWrapper: {
    width: '100%',
    height: '180px',
    margin: '0 0 2rem 0',
    whiteSpace: 'nowrap',
    position: 'relative',
    overflowX: 'scroll',
    overflowY: 'hidden',
  },

  galleryItemPic: {
    width: '24.5%',
    backgroundColor: '#eee',
    float: 'none',
    height: '150px',
    margin: '0 0.25%',
    display: 'inline-block',
    zoom: 1,

    '& img': {
      maxHeight: '70%',
      margin: '.5rem auto',
      display: 'block',
    },
  },

  deleteButton: {
    margin: '0 auto',
    display: 'block',
    width: '40px',
    textAlign: 'center',
  },

  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const SchimbExperienta = (props) => {
  const classes = useStyles();
  // eslint-disable-next-line
  const { history, btnLabel, handleModal } = props;

  const [auth, setAuth] = useState(false);
  const [imagesForSlider, setImagesForSlider] = useState(null);
  const [docs, setDocs] = useState(null);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    setAuth(true);
    loadEntry();
    getGalleryImages();
    getGalleryDocs();
  }, []);

  const getGalleryImages = async () => {
    const answer = await REST.get(`${rURL}/admin/getGallery/experienta/images`);

    const { data } = answer.data;

    let images = data.map((image) => {
      return {
        original: `${GALLERY_PATH}/${image.filename}`,
        thumbnail: `${GALLERY_PATH}/${image.filename}`,
        filename: image.filename,
      };
    });
    setImagesForSlider(images);
  };

  const getGalleryDocs = async () => {
    const answer = await REST.get(`${rURL}/admin/getGallery/experienta/docs`);

    const { data } = answer.data;

    setDocs(data);
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const onDelete = async (filename) => {
    // eslint-disable-next-line
    const result = await REST.post(`${rURL}/admin/deleteGalleryItem`, {
      filename,
    });
    getGalleryImages();
    getGalleryDocs();
  };

  const loadEntry = async () => {
    const answer = await REST.get(`${rURL}/admin/get/experiente`);

    const { content, video } = answer.data.data;

    setFormState((formState) => ({
      values: {
        content,
        video,
      },
      touched: {
        content: true,
      },
      isValid: true,
      errors: {},
    }));
  };

  const handleChangeEditor = (content, editor) => {
    // event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        content,
      },
      touched: {
        content: true,
      },
    }));
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formState.isValid) {
      // eslint-disable-next-line
      const result = await REST.post(
        `${rURL}/admin/update/experiente`,
        formState.values
      );

      window.location = '/admin/rezultate';
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      {auth && (
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <React.Fragment>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Card className={classes.root}>
                    <CardContent>
                      <div className={classes.details}>
                        <div className={classes.headerContainer}>
                          <h2>Schimb de experienta</h2>
                          <AddButton
                            labelName={btnLabel}
                            handleAction={handleModal}
                          />
                        </div>
                        <br />
                      </div>

                      <div className={classes.details}>
                        <div>
                          <h3>Galerie</h3>
                          <br />
                          <h5>(doar .jpeg,.jpg,.png)</h5>
                          <br />
                        </div>
                      </div>

                      <div className={classes.details}>
                        <div>
                          <Uploader
                            usedWith={'experienta'}
                            fileType={'images'}
                          />
                        </div>
                      </div>

                      <div className={classes.details}>
                        <div>
                          <h2>Imagini folosite</h2>
                          <div className={classes.galleryWrapper}>
                            {imagesForSlider &&
                              imagesForSlider.map((galleryImage, index) => {
                                return (
                                  <div className={classes.galleryItemPic}>
                                    <a
                                      href={galleryImage.thumbnail}
                                      target={'_blank'}
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={galleryImage.thumbnail}
                                        alt={''}
                                      />
                                    </a>
                                    <div className={classes.deleteButton}>
                                      <IconButton edge="end" size="small">
                                        <DeleteIcon
                                          onClick={() => {
                                            if (
                                              window.confirm(
                                                'Sunteti siguri ca doriti sa stergeti povestea?'
                                              )
                                            ) {
                                              onDelete(galleryImage.filename);
                                            } else {
                                              console.log('NU!');
                                            }
                                          }}
                                        />
                                      </IconButton>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      <div className={classes.details}>
                        {imagesForSlider && (
                          <ImageGallery
                            // ref={i => this._imageGallery = i}
                            items={imagesForSlider}
                            lazyLoad={false}
                            infinite={true}
                            showBullets={false}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            showThumbnails={true}
                            showIndex={true}
                            showNav={true}
                            isRTL={false}
                            slideDuration={300}
                            slideInterval={3000}
                            additionalClass="app-image-gallery"
                          />
                        )}
                      </div>

                      <div className={classes.details}>
                        <div>
                          <h3>Documente</h3>
                          <br />
                          <h5>(doar .doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf)</h5>
                          <br />
                        </div>
                      </div>

                      <div className={classes.details}>
                        <div>
                          <Uploader usedWith={'experienta'} fileType={'docs'} />
                        </div>
                      </div>

                      <div className={classes.details}>
                        <div>
                          <h2>Documente folosite</h2>
                          <div className={classes.galleryWrapper}>
                            {docs &&
                              docs.map((docItem, index) => {
                                return (
                                  <div className={classes.galleryItemPic}>
                                    <a
                                      href={`${GALLERY_PATH}/${docItem.filename}`}
                                      target={'_blank'}
                                      rel="noreferrer"
                                    >
                                      <IconButton
                                        edge="end"
                                        style={{
                                          display: 'block',
                                          margin: '0 auto',
                                        }}
                                      >
                                        <DescriptionIcon
                                          style={{ fontSize: '2em' }}
                                        />
                                      </IconButton>

                                      <div
                                        style={{
                                          textOverflow: 'ellipsis',
                                          textAlign: 'center',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        {docItem.orig_name}
                                      </div>
                                    </a>
                                    <div className={classes.deleteButton}>
                                      <IconButton edge="end" size="small">
                                        <DeleteIcon
                                          onClick={() => {
                                            if (
                                              window.confirm(
                                                'Sunteti siguri ca vreti sa stergeti documentul?'
                                              )
                                            ) {
                                              onDelete(docItem.filename);
                                            } else {
                                              console.log('NU!');
                                            }
                                          }}
                                        />
                                      </IconButton>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      <div className={classes.details}>
                        <div>
                          <h3>Videoclip</h3>
                          <br />
                          (embed link YouTube)
                          <br />
                        </div>
                      </div>

                      <TextField
                        className={classes.textField}
                        error={hasError('video')}
                        fullWidth
                        helperText={
                          hasError('video') ? formState.errors.title[0] : null
                        }
                        label="Video schimb de experienta"
                        name="video"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.video || ''}
                        variant="outlined"
                      />

                      <br />
                      <br />

                      <div className={classes.details}>
                        <div>
                          <h3>Continut text</h3>
                          <br />
                        </div>
                      </div>

                      {auth && (
                        <Editor
                          value={formState.values.content || ''}
                          id={'experiente-tiny'}
                          key="03cw0gerdgyn5l5bbl8t5kjzugli15q6enn4u4qw4r05ybn4"
                          init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                              'undo redo | formatselect | bold italic backcolor | ' +
                              'alignleft aligncenter alignright alignjustify | ' +
                              'bullist numlist outdent indent | removeformat | help',
                          }}
                          onEditorChange={handleChangeEditor}
                        />
                      )}
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Button
                        className={classes.signInButton}
                        color="primary"
                        disabled={!formState.isValid}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        to={'media'}
                      >
                        Salveaza
                      </Button>
                    </CardActions>
                  </Card>
                </form>
              </div>
            </React.Fragment>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default SchimbExperienta;
