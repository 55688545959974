import axios from 'axios';
import UserService from '@Services/user.service';

let url;

if (
  process &&
  process.env &&
  process.env.NODE_ENV &&
  process.env.NODE_ENV === 'development'
) {
  url = 'http://213.177.29.110:5000/v1';
  // url = 'http://192.168.100.17:8081/v1';
} else {
  url = 'http://213.177.29.110:5000/v1';
  // url = 'http://192.168.100.17:8081/v1';
}

export const rURL = url;

export const MEDIA_PATH = 'http://213.177.29.110:5000/static/media';
export const IMG_PATH = 'http://213.177.29.110:5000/static/images';
export const GALLERY_PATH = 'http://213.177.29.110:5000/static/gallery';

export const REST = axios.create({
  baseURL: url,
  timeout: 10000,
  headers: { 'X-engage-initiator': 'frontend' },
});

export const REST2 = axios.create({
  baseURL: url,
  timeout: 20000,
  headers: { 'X-engage-initiator': 'frontend' },
});

export const logout = () => {
  localStorage.removeItem('accessToken');
  window.top.location = '/';
};

export const isAuthenticated = async () => {
  UserService.hasToken().then(
    (response) => {
      console.log(response.data);
    },
    (error) => {
      console.log(error);
      window.location.href = '/login';
    }
  );
};
