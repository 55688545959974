import { Card, CardContent, Grid, IconButton } from '@material-ui/core';
import ExploreIcon from '@material-ui/icons/Explore';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import SchoolIcon from '@material-ui/icons/School';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { REST, rURL } from '../../../Utils';
import RezultateStyles from './rezultate.style';

const Experts = (props) => {
  const [headerImg, setHeaderImg] = useState(null);

  const classes = RezultateStyles({ headerImg });

  const loadHeaderImage = async () => {
    const answer = await REST.get(`${rURL}/public/getHeadpic/3`);
    const { pic_id_from_media_library } = answer.data.data;
    setHeaderImg(pic_id_from_media_library);
  };

  useEffect(() => {
    loadHeaderImage();
  });

  return (
    <div>
      {headerImg && (
        <Grid
          container
          spacing={4}
          className={clsx(classes.gmaps, classes.gigi)}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
            className={classes.noPadding}
          ></Grid>
        </Grid>
      )}
      <div className={classes.root}>
        <div className={classes.testimonialsContent}>
          <Grid container spacing={4}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <React.Fragment>
                <div className={classes.contentBody}>
                  <Card className={classes.root}>
                    <CardContent>
                      <div className={classes.details}>
                        <div>
                          <h1>Rezultate</h1>
                          <br />
                        </div>
                      </div>

                      <Grid
                        container
                        spacing={4}
                        className={classes.testimonialEntry}
                      >
                        <div className={classes.galleryWrapper}>
                          <div className={classes.galleryItemPic}>
                            <a href={`./rezultate/bune-practici`}>
                              <IconButton
                                edge="end"
                                style={{
                                  display: 'block',
                                  margin: '0 auto',
                                }}
                              >
                                <FitnessCenterIcon
                                  style={{ fontSize: '2em' }}
                                />
                              </IconButton>

                              <div
                                style={{
                                  textOverflow: 'ellipsis',
                                  textAlign: 'center',
                                  overflow: 'hidden',
                                }}
                              >
                                Bune Practici
                              </div>
                            </a>
                          </div>

                          <div className={classes.galleryItemPic}>
                            <a href={`./rezultate/ateliere-de-lucru`}>
                              <IconButton
                                edge="end"
                                style={{
                                  display: 'block',
                                  margin: '0 auto',
                                }}
                              >
                                <SchoolIcon style={{ fontSize: '2em' }} />
                              </IconButton>

                              <div
                                style={{
                                  textOverflow: 'ellipsis',
                                  textAlign: 'center',
                                  overflow: 'hidden',
                                }}
                              >
                                Ateliere De Lucru
                              </div>
                            </a>
                          </div>

                          <div className={classes.galleryItemPic}>
                            <a href={`./rezultate/schimb-de-experienta`}>
                              <IconButton
                                edge="end"
                                style={{
                                  display: 'block',
                                  margin: '0 auto',
                                }}
                              >
                                <ExploreIcon style={{ fontSize: '2em' }} />
                              </IconButton>

                              <div
                                style={{
                                  textOverflow: 'ellipsis',
                                  textAlign: 'center',
                                  overflow: 'hidden',
                                }}
                              >
                                Schimb De Experienta
                              </div>
                            </a>
                          </div>
                        </div>
                      </Grid>
                    </CardContent>
                  </Card>
                </div>
              </React.Fragment>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Experts;
