import {
  Button, Card,
  CardActions,
  CardContent, Divider, Typography
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/styles';
import { MEDIA_PATH } from '@Utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import FileIndexPopup from './FileIndexPopup.component';


const useStyles = makeStyles((theme) => ({
  root: {},
  details: {
    display: 'flex',
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    marginRight: theme.spacing(2),
  },
  image: {
    width: '50%',
    display: 'block',
  },
}));

const AddMediaToEntry = (props) => {
  const { className, fileList, onFileSelect, fileFromEdit, ...rest } = props;

  const [chosenPic, setChosenPic] = useState(fileFromEdit);

  const classes = useStyles();

  const [open, setOpen] = useState(false);


  useEffect(() => {
    setChosenPic(fileFromEdit);
  }, [fileFromEdit]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onFileSelectCallback = (id, filename) => {
    setChosenPic(filename);
    handleClose();
    onFileSelect(id, filename);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography gutterBottom variant="h2">
              Alege o Imagine
            </Typography>
          </div>
          <br />
        </div>


        {chosenPic && (
          <div>
            <img
              alt="Product"
              className={classes.image}
              src={`${MEDIA_PATH}/${chosenPic}`}
            />
          </div>
        )}

      </CardContent>
      <Divider />
      <CardActions>
        <Button
          onClick={handleOpen}
          className={classes.uploadButton}
          color="primary"
          variant="text"
        >
          Deschide galeria media
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <FileIndexPopup
            media={fileList}
            onFileSelect={onFileSelectCallback}
          />
        </Modal>
        <Button variant="text" onClick={() => onFileSelectCallback(null, null)}>
          Sterge poza
        </Button>
      </CardActions>
    </Card>
  );
};

export default AddMediaToEntry;
