import { makeStyles } from '@material-ui/styles';

const IndexStyle = makeStyles((theme) => ({
  root: {
    margin: `0 0 6rem`,
    textAlign: `left`,
    padding: `0 !important`,
    '& *': {
      fontFamily: `Arial`,
    },
    '& h1': {
      fontSize: `2rem`,
      color: `#151b74`,
      margin: `2rem 0`,
      textTransform: `uppercase`,
    },
  },

  noPadding: {
    padding: `0 !important`,
  },

  gmaps: {
    textAlign: `center`,
    padding: `0 !important`,
    height: `500px`,
    width: `100%`,
    margin: `0 0 2rem`,
  },
  gmapsWrap: {
    margin: `0`,
    position: `relative`,
    '.gmapsLabel': {
      position: `absolute`,
      bottom: `0`,
      left: `0`,
    },
  },
  institutions: {
    maxWidth: `1400px`,
    margin: `1rem auto`,
    padding: `0 10px`,
  },
  toggler: {
    margin: `2rem 0 2rem`,
    '& a': {
      color: `#151b74`,
      padding: `0 0 .5rem 0`,
      cursor: `pointer`,
      '&:first-child': {
        margin: `0 1rem 0 0`,
      },
    },
  },
  activeToggledBtn: {
    borderBottom: `1px solid`,
  },
}));

export default IndexStyle;
