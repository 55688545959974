import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
// import InstitutionsGmapsList from '../../../components/Gmaps/InstitutionsGmapsList';
import { REST, rURL } from '../../../Utils';
import ContactStyle from './contact.style';

const Contact = () => {
  // eslint-disable-next-line
  const [entries, setEntries] = useState(null);
  const [headerImg, setHeaderImg] = useState(null);

  const classes = ContactStyle({ headerImg });

  useEffect(() => {
    getInstitutionList();
    loadHeadeImage();
  }, []);

  const loadHeadeImage = async () => {
    const answer = await REST.get(`${rURL}/public/getHeadpic/2`);
    const { pic_id_from_media_library } = answer.data.data;
    setHeaderImg(pic_id_from_media_library);
  };

  const getInstitutionList = async () => {
    const getList = await REST.get(`/public/institutions`);
    setEntries(getList.data.data);
  };

  return (
    <div>
      {headerImg && (
        <Grid
          container
          spacing={4}
          className={clsx(classes.gmaps, classes.gigi)}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
            className={classes.noPadding}
          ></Grid>
        </Grid>
      )}
      <div className={classes.root}>
        <h1>Contact</h1>
      </div>
    </div>
  );
};

export default Contact;
