import { makeStyles } from '@material-ui/styles';
import BeforeBlockquote from '../../../Assets/Images/1.svg';
import AfterBlockquote from '../../../Assets/Images/2.svg';

const TestimonialPartialStyle = makeStyles((theme) => ({
  root: {},
  testimonialsContent: {
    padding: `1rem`,
    '& h2': {
      textTransform: `capitalize`,
      fontSize: `2rem`,
      fontFamily: `Arial`,
      margin: `1rem 0`,
    },
    margin: `10rem 0 3rem !important`,
    position: `relative`,
  },
  testimonialsBeforeBlockquote: {
    background: `url(${BeforeBlockquote})`,
    height: `200px`,
    backgroundSize: `100%`,
    backgroundRepeat: `no-repeat`,
    width: `250px`,
    position: `absolute`,
    top: `-120px`,
    left: 0,
    zIndex: -1,
  },
  testimonialsAfterBlockquote: {
    background: `url(${AfterBlockquote})`,
    height: `200px`,
    backgroundSize: `100%`,
    backgroundRepeat: `no-repeat`,
    width: `250px`,
    position: `absolute`,
    bottom: `-200px`,
    right: 0,
    zIndex: -1,
  },
  testimonialEntry: {},

  testimonialItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    '@media (max-width: 960px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      alignItems: 'center',
    },
  },
  testimonialAvatar: {
    '& img': {
      borderRadius: `100px`,
      maxWidth: '250px',
      maxHeight: '250px',
    },
  },
  testimonialWrap: {
    width: '45%',
    marginLeft: '15px',
    
    '@media (max-width: 960px)': {
      width: '90%',
      height: '100%',
    },
  },

  testimonialText: {
    fontSize: `1.1rem`,
    lineHeight: `1.7`,
    fontStyle: 'italic'
  },
  testimonialName: {
    color: `#151b74 !important`,
    fontSize: `1.2rem`,
    marginTop: 10
  },

  testimonialFunctie: {
    color: `#151b74 !important`,
    fontSize: `1.4rem`,
  },
}));

export default TestimonialPartialStyle;
