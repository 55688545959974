import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { REST, rURL } from '../../../Utils/index';
import FileIndex from './components/FileIndex.component';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },

  dropzone: {
    border: '2px solid #e3e3e3',
    borderRadius: 10,
    textAlign: 'center',
    // fontFamily: 'Arial',
    margin: `0 0 20px`,
    fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif`,
  },

  dropParagraph: {
    padding: '40px',
  },

  fontFamily: {
    fontFamily: 'Arial',
  },
}));

const MediaLibrary = (props) => {
  const classes = useStyles();

  const [auth, setAuth] = useState(false);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    // UserService.hasToken().then(
    //   (response) => {
    setAuth(true);
    getFileList();
    //   },
    //   (error) => {
    //     setAuth(false);
    //     window.location.href = '/login';
    //   }
    // );
  }, []);

  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      // console.log(fileList);
    } else didMountRef.current = true;
  });

  const getFileList = async () => {
    const newFileList = await REST.get(`${rURL}/admin/getFileList`);
    setFileList(newFileList.data.data);
  };

  const onUpload = async (files) => {
    const formData = new FormData();

    files.map((item) => {
      formData.append('photos', item);
      return null;
    });

    const result = await REST.post(`${rURL}/admin/upload`, formData).then(
      (answer) => {
        if (answer.data.status === true) {
          window.location = '/admin/media';
        }
      }
    );
  };

  const onDelete = async (filename) => {
    let body = {
      filename: `${filename}`,
    };
    const result = await REST.post(`${rURL}/admin/delete-media`, body).then(
      (answer) => {
        if (answer.data.status === true) {
          window.location = '/admin/media';
        }
        getFileList();
      }
    );
  };

  return (
    <div className={classes.root}>
      {auth && (
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <h1 className={classes.fontFamily}>Librarie Media</h1>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <React.Fragment>
              <Dropzone
                onDrop={onUpload}
                accept={'.jpeg,.jpg,.png,.ppt,.pdf,.doc'}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className={classes.dropzone}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className={classes.dropParagraph}>
                        Click sau Drag 'n' drop
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>

              {fileList.length > 0 && (
                <FileIndex media={fileList} onDelete={onDelete} />
              )}
            </React.Fragment>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default MediaLibrary;
