import { Button } from '@material-ui/core';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React, { useEffect, useState } from 'react';
import iconMarker from '../../Assets/Images/pin2.svg';
import InfoWindowEx from './InfoWindowEx';

const gglApiKey = process.env.REACT_APP_GMAP_API_KEY;

const InstitutionsGmapsList = (props) => {
  const { entries, google } = props;

  const mapStyles = {
    // width: '100%',
    height: props.height || '500px',
    margin: `0`,
    padding: `0`,
  };

  const [state, setState] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  });

  useEffect(() => {
    setState({ showingInfoWindow: false, activeMarker: {}, selectedPlace: {} });
  }, []);

  const onMarkerClick = (propsM, marker, e) => {
    setState({
      selectedPlace: propsM.place_,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  return (
    <div className="map-container">
      {entries && (
        <Map
          style={mapStyles}
          google={google}
          className={'map'}
          initialCenter={{
            lat: 45.9317763,
            lng: 25.9817005,
          }}
          zoom={8}
        >
          {entries.map((place, i) => {
            return (
              <Marker
                icon={iconMarker}
                onClick={onMarkerClick}
                key={place.id}
                place_={place}
                position={{ lat: place.lat, lng: place.lng }}
              />
            );
          })}
          <InfoWindowEx
            marker={state.activeMarker}
            visible={state.showingInfoWindow}
          >
            <div>
              <h3>{state.selectedPlace.title}</h3>
              <br />
              <h5>{state.selectedPlace.address_line_2}</h5>
              <br />
              <Button
                href={`/institutii/${state.selectedPlace.slug}`}
                color="primary"
                variant="contained"
              >
                Vezi locatia
              </Button>
            </div>
          </InfoWindowEx>
        </Map>
      )}
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: gglApiKey,
})(InstitutionsGmapsList);
