import { Divider, Drawer } from '@material-ui/core';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ImageIcon from '@material-ui/icons/Image';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PeopleIcon from '@material-ui/icons/People';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import WidgetsIcon from '@material-ui/icons/Widgets';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Profile, SidebarNav } from './components';


const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 84,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Cartonase Homepage',
      href: '/admin/homepage-widgets',
      icon: <WidgetsIcon />,
    },
    {
      title: 'Institutii',
      href: '/admin/institutions',
      icon: <TextFieldsIcon />,
    },
    {
      title: 'Testimoniale',
      href: '/admin/testimonials',
      icon: <PeopleIcon />,
    },
    {
      title: 'Rezultate',
      href: '/admin/procese-achizitii',
      icon: <PeopleIcon />,
    },
    {
      title: 'REZULTATE',
      href: '/admin/rezultate',
      icon: <PeopleIcon />,
    },
    {
      title: 'Mesaje Contact',
      href: '/admin/contact',
      icon: <EmailIcon />,
    },
    {
      title: 'Despre Noi',
      href: '/admin/about',
      icon: <ImportContactsIcon />,
    },
    {
      title: 'Homepage Titlu Principal',
      href: '/admin/homepage-title',
      icon: <HomeIcon />,
    },
    {
      title: 'Homepage Intermezzo',
      href: '/admin/homepage-intermezzo',
      icon: <HomeWorkIcon />,
    },
    {
      title: 'Imagini de header',
      href: '/admin/headpics',
      icon: <CameraEnhanceIcon />,
    },
    {
      title: 'Librarie Media',
      href: '/admin/media',
      icon: <ImageIcon />,
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
