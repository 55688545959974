import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import validate from 'validate.js';
import { getHistoryPathArray } from '../../../Helpers';
import { REST, rURL } from '../../../Utils/index';
import HpgWidgetsAddStyle from './HpgWidgetsAdd.style';
import schema from './schema';

const AdminHomepageWidgetsAdd = (props) => {
  const classes = HpgWidgetsAddStyle();
  const { history } = props;

  // eslint-disable-next-line
  const [auth, setAuth] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const isEditMode = history.location.pathname.includes('edit');

  useEffect(() => {

    setAuth(true);
    if (isEditMode) {
      const entryId = getHistoryPathArray(history);
      // setEntryId(entryId.slice(-1));
      loadEntry(entryId.slice(-1));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const loadEntry = async (entryId) => {
    const answer = await REST.get(`${rURL}/admin/getWidgetEntry/${entryId}`);
    const { show_this_number, content } = answer.data.data;

    setFormState((formState) => ({
      values: {
        show_this_number,
        content,
      },
      touched: {
        show_this_number: true,
        content: true,
      },
      isValid: true,
      errors: {},
    }));
  };

  // eslint-disable-next-line
  const handleBack = () => {
    // history.push('/');
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let url;

    if (formState.isValid) {
      const entryId = getHistoryPathArray(history);
      formState.values.id = entryId.slice(-1).toString();
      if (isEditMode) url = `${rURL}/admin/update-widget/`;
      else url = `${rURL}/admin/add-widget`;

      // eslint-disable-next-line
      const result = await REST.post(url, formState.values);

      history.push('/admin/homepage-widgets');
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <h1 className={classes.fontFamily}>Add a Widget</h1>
        </Grid>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <React.Fragment>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSubmit}>
                <Card className={clsx(classes.root)}>
                  <CardContent>
                    <div className={classes.details}>
                      <div>
                        <Typography gutterBottom variant="h2">
                          Nume si continut
                        </Typography>
                      </div>
                    </div>

                    <TextField
                      className={classes.textField}
                      error={hasError('show_this_number')}
                      fullWidth
                      helperText={
                        hasError('show_this_number')
                          ? formState.errors.show_this_number[0]
                          : null
                      }
                      label={'Numar'}
                      name="show_this_number"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.show_this_number || ''}
                      variant="outlined"
                    />

                    <TextField
                      multiline
                      rows={10}
                      aria-multiline={true}
                      className={classes.textField}
                      error={hasError('content')}
                      fullWidth
                      helperText={
                        hasError('content') ? formState.errors.content[0] : null
                      }
                      label="Continut"
                      name="content"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.content || ''}
                      variant="outlined"
                    />
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      disabled={!formState.isValid}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      to={'media'}
                    >
                      Save entry
                    </Button>
                  </CardActions>
                </Card>
              </form>
            </div>
          </React.Fragment>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminHomepageWidgetsAdd;
