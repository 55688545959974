import { makeStyles } from '@material-ui/styles';
import { REST, rURL } from '@Utils';
import React, { useEffect, useRef, useState } from 'react';
import AdminTestimonialsToolbar from './AdminHomepageWidgetsToolbar.component';
import HomepageWidgetsIndex from './HomepageWidgetsIndex.component';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },

  content: {
    marginTop: theme.spacing(2),
  },

  dropzone: {
    border: '2px solid #e3e3e3',
    borderRadius: 10,
    textAlign: 'center',
    // fontFamily: 'Arial',
    margin: `0 0 20px`,
    fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif`,
  },

  dropParagraph: {
    padding: '40px',
  },

  fontFamily: {
    fontFamily: 'Arial',
  },
}));

const AdminHomepageWidgets = () => {
  const classes = useStyles();

  // eslint-disable-next-line
  const [auth, setAuth] = useState(false);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {

    setAuth(true);
    getTestimonialsList();
  
  }, []);

  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
    } else didMountRef.current = true;
  });

  const getTestimonialsList = async () => {
    const newFileList = await REST.get(`${rURL}/admin/getWidgetsList`);
    setFileList(newFileList.data.data);
  };

  const onDelete = async (id) => {
    // eslint-disable-next-line
    const result = await REST.post(`${rURL}/admin/delete-widget`, {
      id,
    });
    getTestimonialsList();
  };

  return (
    <div className={classes.root}>
      <AdminTestimonialsToolbar />
      {fileList.length > 0 && (
        <div className={classes.content}>
          <HomepageWidgetsIndex entries={fileList} onDelete={onDelete} />
        </div>
      )}
    </div>
  );
};

export default AdminHomepageWidgets;
