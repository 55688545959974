import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import validate from 'validate.js';
import { getHistoryPathArray, slugify } from '../../../Helpers';
import { REST, rURL } from '../../../Utils/index';
import AddMediaToEntry from '../MediaLibrary/components/AddMediaToEntry.component';
import useStyles from './AdminInstitutions.style';
import Gmaps from './components/Gmaps';
import schema from './schema';

const AdminInstitutions = (props) => {
  const classes = useStyles();
  const { history } = props;

  const [auth, setAuth] = useState(false);
  const [cities, setCities] = useState([]);
  const [counties, setCounties] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [fileFromEdit, setFileFromEdit] = useState(null);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const isEditMode = history.location.pathname.includes('edit');

  useEffect(() => {
   
    setAuth(true);
    getFileList();
    getCounties();
    if (isEditMode) {
      const entrySlug = getHistoryPathArray(history);
      loadEntry(entrySlug.slice(-1));
    }
   // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const loadEntry = async (entrySlug) => {
    const answer = await REST.get(
      `${rURL}/admin/getInstitutionEntry/${entrySlug}`
    );
    const {
      id,
      title,
      slug,
      lat,
      lng,
      pic_id_from_media_library,
      county_id,
      city_id,
      city_name,
      address_line_1,
      valoare_contracte_incheiate,
      numar_achizitii_publice,
      numar_proceduri_anulate,
      durata_procedura_achizitii,
      numar_mediu_oferte,
      // numar_mediu_contestatii,
      domeniu_activitate,
      procedura_1,
      procedura_2,
      procedura_3,
      // siruta,
      dimensiune,
    } = answer.data.data;

    setFormState((formState) => ({
      values: {
        id,
        title,
        slug,
        lat,
        lng,
        pic_id_from_media_library,
        county_id,
        city_id,
        city_name,
        address_line_1,
        valoare_contracte_incheiate,
        numar_achizitii_publice,
        numar_proceduri_anulate,
        durata_procedura_achizitii,
        numar_mediu_oferte,
        // numar_mediu_contestatii,
        domeniu_activitate,
        procedura_1,
        procedura_2,
        procedura_3,
        // siruta,
        dimensiune,
      },
      touched: {
        title: true,
        lat: true,
        lng: true,
        county_id: true,
        city_id: true,
        city_name: true,
        address_line_1: true,
        valoare_contracte_incheiate: true,
        numar_achizitii_publice: true,
        numar_proceduri_anulate: true,
        durata_procedura_achizitii: true,
        numar_mediu_oferte: true,
        // numar_mediu_contestatii: true,
        domeniu_activitate: true,
        procedura_1: true,
        procedura_2: true,
        procedura_3: true,
        siruta: true,
        dimensiune: true,
      },
      isValid: true,
      errors: {},
    }));

    setFileFromEdit(pic_id_from_media_library);
    // console.log(answer.data.data);
    filterCitiesByCountyId(county_id);
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const updateLatLng = (lat, lng) => {
    formState.values.lat = lat;
    formState.values.lng = lng;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let url;

    if (formState.isValid) {
      if (isEditMode) {
        url = `${rURL}/admin/update-institution`;
      } else {
        formState.values.slug = slugify(
          `${
            formState.values.title
              ? formState.values.title + '-' + formState.values.city_name
              : formState.values.city_name
          }`
        );
        url = `${rURL}/admin/add-institution`;
      }

      // eslint-disable-next-line
      const result = await REST.post(url, formState.values);

      history.push('/admin/institutions');
    }
  };

  const getCounties = async () => {
    const countiesList = await REST.get(`${rURL}/admin/getCounties`);
    setCounties(countiesList.data.data);
  };

  const getFileList = async () => {
    const newFileList = await REST.get(`${rURL}/admin/getFileList`);
    setFileList(newFileList.data.data);
  };

  const onFileSelect = (id, filename) => {
    setFileFromEdit(null);
    formState.values.pic_id_from_media_library = filename;
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const onChangeCity = (event, values) => {
    if (values) {
      formState.values.city_name = values.name;
      formState.values.city_id = values.id;
      updateLatLng(values.lat, values.long);
    }
  };

  const onChangeCounty = (event) => {
    setSelectedCounty(event.target.value);
    formState.values.county_id = event.target.value;
    filterCitiesByCountyId(event.target.value);
  };

  const filterCitiesByCountyId = async (countyId) => {
    const getCities = await REST.post(`${rURL}/admin/filter-cities-by-county`, {
      countyId,
    });
    setCities(getCities.data.data);
  };

  return (
    <div className={classes.root}>
      {auth && (
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <h1 className={classes.fontFamily}>
              {isEditMode ? `Modifica institutia` : `Adauga o Institutie`}
            </h1>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <React.Fragment>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <AddMediaToEntry
                    fileList={fileList}
                    onFileSelect={onFileSelect}
                    fileFromEdit={fileFromEdit}
                  />

                  <Card className={clsx(classes.root)}>
                    <CardContent>
                      <div className={classes.details}>
                        <div>
                          <Typography gutterBottom variant="h2">
                            Adresa si nume
                          </Typography>
                        </div>
                      </div>

                      <TextField
                        className={classes.textField}
                        error={hasError('title')}
                        fullWidth
                        helperText={
                          hasError('title') ? formState.errors.title[0] : null
                        }
                        label="Nume Institutie"
                        name="title"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.title || ''}
                        variant="outlined"
                      />

                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="dimensiune-label">
                          Dimensiune
                        </InputLabel>
                        <Select
                          labelId="dimensiune-label"
                          id="dimensiune"
                          value={formState.values.dimensiune || ''}
                          onChange={handleChange}
                          name={'dimensiune'}
                        >
                          <MenuItem value={'mare'}>
                            Mare ( &gt; 200 mil. Lei anual)
                          </MenuItem>
                          <MenuItem value={'medie'}>
                            Medie (10-200 mil. Lei anual)
                          </MenuItem>
                          <MenuItem value={'mica'}>
                            Mica (&lt; 10 mil. lei anual)
                          </MenuItem>
                        </Select>
                      </FormControl>

                      {/*<TextField*/}
                      {/*  className={classes.siruta}*/}
                      {/*  error={hasError('siruta')}*/}
                      {/*  fullWidth*/}
                      {/*  // helperText={*/}
                      {/*  //   hasError('siruta') ? formState.errors.siruta[0] : null*/}
                      {/*  // }*/}
                      {/*  label="Siruta"*/}
                      {/*  name="siruta"*/}
                      {/*  onChange={handleChange}*/}
                      {/*  type="text"*/}
                      {/*  value={formState.values.siruta || ''}*/}
                      {/*  variant="outlined"*/}
                      {/*/>*/}

                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="county_id">Alege judet</InputLabel>
                        <Select
                          name={'county_id'}
                          labelId="county_id"
                          id="county_id"
                          value={
                            selectedCounty || formState.values.county_id || ''
                          }
                          onChange={onChangeCounty}
                          label="Alege judet"
                        >
                          <MenuItem value="">
                            <em>--judet--</em>
                          </MenuItem>
                          {counties.map((item, ii) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Autocomplete
                          options={cities}
                          getOptionLabel={(option) => option.name}
                          onChange={onChangeCity}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={'city'}
                              error={hasError('city')}
                              defaultValue={formState.values.city_id || 0}
                              variant="standard"
                              label="Orasul institutiei"
                              placeholder="Alege Orasul"
                              margin="normal"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>

                      <TextField
                        className={classes.textField}
                        error={hasError('address_line_1')}
                        fullWidth
                        helperText={
                          hasError('address_line_1')
                            ? formState.errors.address_line_1[0]
                            : null
                        }
                        label="Adresa"
                        name="address_line_1"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.address_line_1 || ''}
                        variant="outlined"
                      />

                      <div className={classes.gmaps}>
                        <Gmaps
                          lat={formState.values.lat || null}
                          lng={formState.values.lng || null}
                          updateLatLng={updateLatLng}
                        />
                      </div>
                    </CardContent>
                    <Divider />
                    <CardContent>
                      <div className={classes.details}>
                        <div>
                          <Typography gutterBottom variant="h2">
                            Atribute
                          </Typography>
                        </div>
                      </div>

                      <TextField
                        className={classes.textField}
                        error={hasError('valoare_contracte_incheiate')}
                        fullWidth
                        helperText={
                          hasError('valoare_contracte_incheiate')
                            ? formState.errors.valoare_contracte_incheiate[0]
                            : null
                        }
                        label="VALOAREA CONTRACTELOR DE ACHIZIȚIE PUBLICĂ (2019)"
                        name="valoare_contracte_incheiate"
                        onChange={handleChange}
                        type="text"
                        value={
                          formState.values.valoare_contracte_incheiate || ''
                        }
                        variant="outlined"
                      />

                      <TextField
                        className={classes.textField}
                        error={hasError('numar_achizitii_publice')}
                        fullWidth
                        helperText={
                          hasError('numar_achizitii_publice')
                            ? formState.errors.numar_achizitii_publice[0]
                            : null
                        }
                        label="NUMĂRUL CONTRACTELOR DE ACHIZIȚIE PUBLICĂ (2019)"
                        name="numar_achizitii_publice"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.numar_achizitii_publice || ''}
                        variant="outlined"
                      />

                      <TextField
                        className={classes.textField}
                        error={hasError('numar_proceduri_anulate')}
                        fullWidth
                        helperText={
                          hasError('numar_proceduri_anulate')
                            ? formState.errors.numar_proceduri_anulate[0]
                            : null
                        }
                        label="NUMĂRUL PROCEDURILOR DE ACHIZIȚIE PUBLICĂ (2019)"
                        name="numar_proceduri_anulate"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.numar_proceduri_anulate || ''}
                        variant="outlined"
                      />

                      <TextField
                        className={classes.textField}
                        error={hasError('durata_procedura_achizitii')}
                        fullWidth
                        helperText={
                          hasError('durata_procedura_achizitii')
                            ? formState.errors.durata_procedura_achizitii[0]
                            : null
                        }
                        label="DURATA MEDIE A PROCEDURII DE ACHIZIȚIE PUBLICĂ (2019)"
                        name="durata_procedura_achizitii"
                        onChange={handleChange}
                        type="text"
                        value={
                          formState.values.durata_procedura_achizitii || ''
                        }
                        variant="outlined"
                      />

                      <TextField
                        className={classes.textField}
                        error={hasError('numar_mediu_oferte')}
                        fullWidth
                        helperText={
                          hasError('numar_mediu_oferte')
                            ? formState.errors.numar_mediu_oferte[0]
                            : null
                        }
                        label="NUMĂRUL MEDIU DE OFERTE PRIMITE (2019)"
                        name="numar_mediu_oferte"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.numar_mediu_oferte || ''}
                        variant="outlined"
                      />

                      {/*<TextField*/}
                      {/*  className={classes.textField}*/}
                      {/*  error={hasError('numar_mediu_contestatii')}*/}
                      {/*  fullWidth*/}
                      {/*  helperText={*/}
                      {/*    hasError('numar_mediu_contestatii')*/}
                      {/*      ? formState.errors.numar_mediu_contestatii[0]*/}
                      {/*      : null*/}
                      {/*  }*/}
                      {/*  label="Numar mediu contestatii"*/}
                      {/*  name="numar_mediu_contestatii"*/}
                      {/*  onChange={handleChange}*/}
                      {/*  type="text"*/}
                      {/*  value={formState.values.numar_mediu_contestatii || ''}*/}
                      {/*  variant="outlined"*/}
                      {/*/>*/}

                      <TextField
                        className={classes.textField}
                        error={hasError('domeniu_activitate')}
                        fullWidth
                        helperText={
                          hasError('domeniu_activitate')
                            ? formState.errors.domeniu_activitate[0]
                            : null
                        }
                        label="Domeniu de activitate"
                        name="domeniu_activitate"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.domeniu_activitate || ''}
                        variant="outlined"
                      />
                    </CardContent>
                    <Divider />
                    <CardContent>
                      <div className={classes.details}>
                        <div>
                          <Typography gutterBottom variant="h2">
                            Proceduri
                          </Typography>
                        </div>
                      </div>

                      <TextField
                        multiline
                        rows={10}
                        aria-multiline={true}
                        className={classes.textField}
                        error={hasError('procedura_1')}
                        fullWidth
                        helperText={
                          hasError('procedura_1')
                            ? formState.errors.procedura_1[0]
                            : null
                        }
                        label="Procedura 1"
                        name="procedura_1"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.procedura_1 || ''}
                        variant="outlined"
                      />

                      <TextField
                        multiline
                        rows={10}
                        aria-multiline={true}
                        className={classes.textField}
                        error={hasError('procedura_2')}
                        fullWidth
                        helperText={
                          hasError('procedura_2')
                            ? formState.errors.procedura_2[0]
                            : null
                        }
                        label="Procedura 2"
                        name="procedura_2"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.procedura_2 || ''}
                        variant="outlined"
                      />

                      <TextField
                        multiline
                        rows={10}
                        aria-multiline={true}
                        className={classes.textField}
                        error={hasError('procedura_3')}
                        fullWidth
                        helperText={
                          hasError('procedura_3')
                            ? formState.errors.procedura_3[0]
                            : null
                        }
                        label="Procedura 3"
                        name="procedura_3"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.procedura_3 || ''}
                        variant="outlined"
                      />
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Button
                        className={classes.signInButton}
                        color="primary"
                        disabled={!formState.isValid}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        to={'media'}
                      >
                        Save entry
                      </Button>
                    </CardActions>
                  </Card>
                </form>
              </div>
            </React.Fragment>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AdminInstitutions;
