import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import validate from 'validate.js';
import { GALLERY_PATH, REST, rURL } from '../../../../Utils/index';
import schema from '../AteliereLucru/schema';
import Uploader from '../CustomUploader';
import uuid from 'uuid/v1';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
  },
  grid: {
    // height: '100%',
  },
  quote: {
    // backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    alignItems: 'center',
  },

  form: {
    // paddingLeft: 100,
    // paddingRight: 100,
    // paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },

  dropzone: {
    border: '2px solid #e3e3e3',
    borderRadius: 10,
    textAlign: 'center',
    margin: `0 0 20px`,
    fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif`,
  },

  dropParagraph: {
    padding: '40px',
  },

  fontFamily: {
    fontFamily: 'Arial',
  },

  galleryWrapper: {
    width: '100%',
    height: '180px',
    margin: '0 0 2rem 0',
    whiteSpace: 'nowrap',
    position: 'relative',
    overflowX: 'auto',
    overflowY: 'hidden',
  },

  galleryItemPic: {
    width: '24.5%',
    backgroundColor: '#eee',
    float: 'none',
    height: '150px',
    margin: '0 0.25%',
    display: 'inline-block',
    zoom: 1,

    '& img': {
      maxHeight: '70%',
      margin: '.5rem auto',
      display: 'block',
    },
  },

  deleteButton: {
    margin: '0 auto',
    display: 'block',
    width: '40px',
    textAlign: 'center',
  },

  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const AddModal = ({
  title = '',
  slug = '',
  setIsVisible = false,
  item = {},
  edit = false,
  handleCloseEditModal = () => { },
}) => {
  const classes = useStyles();

  const [imagesForSlider, setImagesForSlider] = useState(null);
  const [docs, setDocs] = useState(null);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [institutionList, setInstitutionList] = useState([]);

  useEffect(() => {
    if (!edit) {
      getGalleryDocs();
      // eslint-disable-next-line
    }
    getInstitutions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (edit && item.id) {
      loadEntry();
    }
    // eslint-disable-next-line
  }, [edit]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    // eslint-disable-next-line
  }, [formState.values]);

  const loadEntry = async () => {
    const answer = await REST.get(
      `${rURL}/public/${slug}/getTabById/${item.id}`
    );

    const {
      title,
      nameOfPerson,
      institutionName,
      shortDescription,
      institutionLink,
      content,
      video,
      previewImage,
      conclusion,
    } = answer.data.data[0];

    setFormState((formState) => ({
      values: {
        content: content || null,
        video: video || null,
        shortDescription: shortDescription || null,
        title: title || null,
        institutionLink: institutionLink || null,
        nameOfPerson: nameOfPerson || null,
        previewImage: previewImage || null,
        institutionName: institutionName || null,
        conclusion: conclusion || null,
      },
    }));

    getGalleryImages();
    getGalleryDocs();
  };

  const getGalleryImages = async () => {
    if (edit) {
      const answer = await REST.get(
        `${rURL}/admin/getGallery/${slug}_${item.id}/images`
      );

      const { data } = answer.data;

      let images = data.map((image) => {
        return {
          original: `${GALLERY_PATH}/${image.filename}`,
          thumbnail: `${GALLERY_PATH}/${image.filename}`,
          filename: image.filename,
        };
      });

      setImagesForSlider(images);
    } else if (localStorage.getItem('post-id') !== null) {
      const answer = await REST.get(
        `${rURL}/admin/getGallery/${slug}_${localStorage.getItem(
          'post-id'
        )}/images`
      );

      const { data } = answer.data;

      let images = data.map((image) => {
        return {
          original: `${GALLERY_PATH}/${image.filename}`,
          thumbnail: `${GALLERY_PATH}/${image.filename}`,
          filename: image.filename,
        };
      });

      setImagesForSlider(images);
    }
  };

  const getGalleryDocs = async () => {
    if (edit) {
      const answer = await REST.get(
        `${rURL}/admin/getGallery/${slug}_${item.id}/docs`
      );
      const { data } = answer.data;
      setDocs(data);
    } else {
      const answer = await REST.get(
        `${rURL}/admin/getGallery/${slug}_${localStorage.getItem(
          'post-id'
        )}/docs`
      );
      const { data } = answer.data;
      setDocs(data);
    }
  };

  const getInstitutions = async () => {
    const answer = await REST.get(`${rURL}/public/institutions`);
    const { data } = answer.data;
    setInstitutionList(data);
  };

  const onDelete = async (filename) => {
    // eslint-disable-next-line
    const result = await REST.post(`${rURL}/admin/deleteGalleryItem`, {
      filename,
    });
    getGalleryImages();
    getGalleryDocs();
  };

  const handleChangeEditor = (content, editor) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        content: content,
      },
      touched: {
        content: true,
      },
    }));
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (edit) {
      if (formState.isValid) {
        if (slug === 'ateliere') {
          let bodyToSend = { ...formState.values };
          delete bodyToSend.name;
          bodyToSend = { ...bodyToSend, name: formState.values.title };

          await REST.post(
            `${rURL}/admin/${slug}/updateTab/${item.id}`,
            bodyToSend
          ).then((answer) => {
            setIsVisible(false);
            handleCloseEditModal();
          });
        } else {
          await REST.post(
            `${rURL}/admin/${slug}/updateTab/${item.id}`,
            formState.values
          ).then((answer) => {
            setIsVisible(false);
            handleCloseEditModal();
          });
        }
      }
    } else {
      if (formState.isValid) {
        if (localStorage.getItem('post-id') !== null) {
          if (slug === 'ateliere') {
            let bodyToSend = { ...formState.values };
            delete bodyToSend.name;
            bodyToSend = { ...bodyToSend, name: formState.values.title };

            await REST.post(
              `${rURL}/admin/${slug}/updateTab/${localStorage.getItem(
                'post-id'
              )}`,
              bodyToSend
            ).then(() => {
              setIsVisible(false);
              handleCloseEditModal();
            });
          } else {
            await REST.post(
              `${rURL}/admin/${slug}/updateTab/${localStorage.getItem(
                'post-id'
              )}`,
              formState.values
            ).then(() => {
              setIsVisible(false);
              handleCloseEditModal();
            });
          }
        } else {
          await REST.post(`${rURL}/admin/${slug}/addTab`).then((answer) => {
            localStorage.setItem('post-id', answer.data.data.id);
            if (slug === 'ateliere') {
              let bodyToSend = { ...formState.values };
              delete bodyToSend.name;
              bodyToSend = { ...bodyToSend, name: formState.values.title };

              REST.post(
                `${rURL}/admin/${slug}/updateTab/${answer.data.data.id}`,
                bodyToSend
              ).then(() => {
                setIsVisible(false);
                handleCloseEditModal();
              });
            } else {
              REST.post(
                `${rURL}/admin/${slug}/updateTab/${answer.data.data.id}`,
                formState.values
              ).then(() => {
                setIsVisible(false);
                handleCloseEditModal();
              });
            }
          });
        }
      }
    }
  };

  // const hasError = (field) =>
  //   formState.touched[field] && formState.errors[field] ? true : false;

  const hasError = (field) => false;

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <React.Fragment>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSubmit}>
                <Card className={classes.root}>
                  <CardContent>
                    <div className={classes.details}>
                      <div className={classes.headerContainer}>
                        <h2>
                          {edit ? `Editati` : `Adaugati`} {title}
                        </h2>
                      </div>
                      <br />
                    </div>

                    <div className={classes.details}>
                      {imagesForSlider && (
                        <ImageGallery
                          // ref={i => this._imageGallery = i}
                          items={imagesForSlider}
                          lazyLoad={false}
                          infinite={true}
                          showBullets={false}
                          showFullscreenButton={false}
                          showPlayButton={false}
                          showThumbnails={true}
                          showIndex={true}
                          showNav={true}
                          isRTL={false}
                          slideDuration={300}
                          slideInterval={3000}
                          additionalClass="app-image-gallery"
                        />
                      )}
                    </div>

                    {slug === 'ateliere' && (
                      <div>
                        <div>
                          <div className={classes.details}>
                            <div>
                              <br />
                              <h3>Nume Etapa</h3>
                              <br />
                            </div>
                          </div>

                          <TextField
                            className={classes.textField}
                            fullWidth
                            placeholder="Nume Etapa"
                            name="title"
                            onChange={handleChange}
                            type="text"
                            value={formState.values.title || ''}
                            variant="outlined"
                          />
                        </div>

                        <div>
                          <div className={classes.details}>
                            <div>
                              <br />
                              <h3>Scurta descriere</h3>
                              <br />
                            </div>
                          </div>

                          <TextField
                            className={classes.textField}
                            error={hasError('video')}
                            fullWidth
                            helperText={
                              hasError('video')
                                ? formState.errors.title[0]
                                : null
                            }
                            placeholder="Scurta descriere"
                            name="shortDescription"
                            onChange={handleChange}
                            type="text"
                            value={formState.values.shortDescription || ''}
                            variant="outlined"
                          />
                        </div>
                      </div>
                    )}

                    {slug === 'experiente' && (
                      <div>
                        <div>
                          <div className={classes.details}>
                            <div>
                              <br />
                              <h3>Titlu</h3>
                              <br />
                            </div>
                          </div>

                          <TextField
                            className={classes.textField}
                            error={hasError('video')}
                            fullWidth
                            helperText={
                              hasError('video')
                                ? formState.errors.title[0]
                                : null
                            }
                            placeholder="Titlu"
                            name="title"
                            onChange={handleChange}
                            type="text"
                            value={formState.values.title || ''}
                            variant="outlined"
                          />
                        </div>

                        <div>
                          <div className={classes.details}>
                            <div>
                              <br />
                              <h3>De reținut</h3>
                              <br />
                            </div>
                          </div>

                          <TextField
                            className={classes.textField}
                            error={hasError('video')}
                            fullWidth
                            helperText={
                              hasError('video')
                                ? formState.errors.title[0]
                                : null
                            }
                            placeholder="De reținut"
                            name="conclusion"
                            onChange={handleChange}
                            type="text"
                            value={formState.values.conclusion || ''}
                            variant="outlined"
                          />
                        </div>
                      </div>
                    )}

                    {slug === 'practici' ? (
                      <div>
                        <div>
                          <div className={classes.details}>
                            <div>
                              <br />
                              <h3>Titlu</h3>
                              <br />
                            </div>
                          </div>

                          <TextField
                            className={classes.textField}
                            error={hasError('video')}
                            fullWidth
                            helperText={
                              hasError('video')
                                ? formState.errors.title[0]
                                : null
                            }
                            placeholder="Titlu"
                            name="title"
                            onChange={handleChange}
                            type="text"
                            value={formState.values.title || ''}
                            variant="outlined"
                          />
                        </div>
                        <div>
                          <div className={classes.details}>
                            <div>
                              <br />
                              <h3>Institutie</h3>
                              <br />
                            </div>
                          </div>

                          <Autocomplete
                            id="institutionName"
                            options={institutionList}
                            getOptionLabel={(option) => option.title}
                            style={{ width: 300 }}
                            onChange={(event, newValue) => {
                              setFormState((state) => {
                                return {
                                  ...state,
                                  values: {
                                    ...state.values,
                                    institutionName: newValue.title,
                                  },
                                };
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Institutie"
                                variant="outlined"
                                value={formState.values.institutionName || ''}
                              />
                            )}
                          />
                        </div>

                        <div>
                          <div className={classes.details}>
                            <div>
                              <br />
                              <h3>Numele Intervievat</h3>
                              <br />
                            </div>
                          </div>

                          <TextField
                            className={classes.textField}
                            error={hasError('video')}
                            fullWidth
                            helperText={
                              hasError('video')
                                ? formState.errors.title[0]
                                : null
                            }
                            // label="Numele Intervievat"
                            placeholder="Numele Intervievat"
                            name="nameOfPerson"
                            onChange={handleChange}
                            type="text"
                            value={formState.values.nameOfPerson || ''}
                            variant="outlined"
                          />
                        </div>

                        <div>
                          <div className={classes.details}>
                            <div>
                              <br />
                              <h3>Scurta Descriere</h3>
                              <br />
                            </div>
                          </div>

                          <TextField
                            className={classes.textField}
                            error={hasError('video')}
                            fullWidth
                            helperText={
                              hasError('video')
                                ? formState.errors.title[0]
                                : null
                            }
                            // label="Numele Intervievat"
                            placeholder="Scurta Descriere"
                            name="shortDescription"
                            onChange={handleChange}
                            type="text"
                            value={formState.values.shortDescription || ''}
                            variant="outlined"
                          />
                        </div>
                      </div>
                    ) : null}

                    <div className={classes.details}>
                      <div>
                        <br />
                        <h3>Continut text</h3>
                        <br />
                      </div>

                      <Editor
                        // value={formState?.values?.content || ''}
                        key="03cw0gerdgyn5l5bbl8t5kjzugli15q6enn4u4qw4r05ybn4"
                        id={`modal-tiny`}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | ' +
                            'alignleft aligncenter alignright alignjustify | ' +
                            'bullist numlist outdent indent | removeformat | help',
                        }}
                        onEditorChange={handleChangeEditor}
                        value={formState.values.content}
                      />
                    </div>

                    <br />

                    <div className={classes.details}>
                      <div>
                        <h3>Galerie</h3>
                        <br />
                        <h5>(doar .jpeg,.jpg,.png)</h5>
                        <br />
                      </div>
                    </div>

                    <div className={classes.details}>
                      <div>
                        <Uploader
                          usedWith={'ateliere'}
                          fileType={'images'}
                          slug={slug}
                          getGalleryImages={getGalleryImages}
                          edit={edit}
                          id={edit ? item.id : null}
                        />
                      </div>
                    </div>

                    <div className={classes.details}>
                      <div>
                        <h2>Imagini folosite</h2>
                        <div className={classes.galleryWrapper}>
                          {imagesForSlider &&
                            imagesForSlider.map((galleryImage) => {
                              return (
                                <div
                                  className={classes.galleryItemPic}
                                  key={uuid()}
                                >
                                  <a
                                    href={galleryImage.thumbnail}
                                    target={'_blank'}
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={galleryImage.thumbnail}
                                      alt={''}
                                    />
                                  </a>
                                  <div className={classes.deleteButton}>
                                    <IconButton edge="end" size="small">
                                      <DeleteIcon
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              'Sunteti siguri ca vreti sa stergeti poza?'
                                            )
                                          ) {
                                            onDelete(galleryImage.filename);
                                          } else {
                                            console.log('NU!');
                                          }
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>

                    <br />

                    <div className={classes.details}>
                      <div>
                        <h3>Documente</h3>
                        <br />
                        <h5>(doar .doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf)</h5>
                        <br />
                      </div>
                    </div>

                    <div className={classes.details}>
                      <div>
                        <Uploader
                          usedWith={'ateliere'}
                          fileType={'docs'}
                          getGalleryImages={getGalleryDocs}
                          slug={slug}
                          edit={edit}
                          id={edit ? item.id : null}
                        />
                      </div>
                    </div>

                    <div className={classes.details}>
                      <div>
                        <h2>Documente folosite</h2>
                        <div className={classes.galleryWrapper}>
                          {docs &&
                            docs.map((docItem, index) => {
                              return (
                                <div
                                  className={classes.galleryItemPic}
                                  key={uuid()}
                                >
                                  <a
                                    href={`${GALLERY_PATH}/${docItem.filename}`}
                                    target={'_blank'}
                                    rel="noreferrer"
                                  >
                                    <IconButton
                                      edge="end"
                                      style={{
                                        display: 'block',
                                        margin: '0 auto',
                                      }}
                                    >
                                      <DescriptionIcon
                                        style={{ fontSize: '2em' }}
                                      />
                                    </IconButton>

                                    <div
                                      style={{
                                        textOverflow: 'ellipsis',
                                        textAlign: 'center',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {docItem.orig_name}
                                    </div>
                                  </a>
                                  <div className={classes.deleteButton}>
                                    <IconButton edge="end" size="small">
                                      <DeleteIcon
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              'Sunteti siguri ca vreti sa stergeti documentul?'
                                            )
                                          ) {
                                            onDelete(docItem.filename);
                                          } else {
                                            console.log('NU!');
                                          }
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>

                    <br />

                    <div className={classes.details}>
                      <div>
                        <h3>Videoclip</h3>
                        <br />
                        (embed link YouTube)
                        <br />
                      </div>
                    </div>

                    <TextField
                      className={classes.textField}
                      error={hasError('video')}
                      fullWidth
                      helperText={
                        hasError('video') ? formState.errors.title[0] : null
                      }
                      label="Video ateliere de lucru"
                      name="video"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.video || ''}
                      variant="outlined"
                    />
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      disabled={!formState.isValid}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      to={'media'}
                    >
                      Salvează
                    </Button>
                  </CardActions>
                </Card>
              </form>
            </div>
          </React.Fragment>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddModal;
