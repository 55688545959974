import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// import InstitutionsGmapsList from '../../../components/Gmaps/InstitutionsGmapsList';
import { REST, rURL } from '../../../Utils';
import clsx from 'clsx';
import AboutStyle from './about.style';

const About = () => {
  // eslint-disable-next-line
  const [entries, setEntries] = useState(null);
  const [about, setAbout] = useState(null);
  const [headerImg, setHeaderImg] = useState(null);

  const classes = AboutStyle({ headerImg });

  useEffect(() => {
    getInstitutionList();
    loadAbout();
    loadHeadeImage();
  }, []);

  const getInstitutionList = async () => {
    const getList = await REST.get(`${rURL}/public/institutions`);
    setEntries(getList.data.data);
  };

  const loadAbout = async () => {
    const answer = await REST.get(`${rURL}/public/about`);
    setAbout(answer.data.data);
  };

  const loadHeadeImage = async () => {
    const answer = await REST.get(`${rURL}/admin/getHeadpic/1`);
    const { pic_id_from_media_library } = answer.data.data;
    setHeaderImg(pic_id_from_media_library);
  };

  const aboutFormatting = (content) => {
    return {
      __html: content,
    };
  };

  return (
    <div>
      {headerImg && (
        <Grid
          container
          spacing={4}
          className={clsx(classes.gmaps, classes.gigi)}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
            className={classes.noPadding}
          ></Grid>
        </Grid>
      )}
      <div className={classes.root}>
        <h1>Despre Proiect</h1>
        {about && (
          <div dangerouslySetInnerHTML={aboutFormatting(about.content)} />
        )}
      </div>
    </div>
  );
};

export default About;
