import { makeStyles } from '@material-ui/styles';

const InstitutionEntryStyle = makeStyles((theme) => ({
  root: {
    textAlign: `left`,
    padding: `0 !important`,
    '& *': {
      fontFamily: `Arial`,
    },
  },
  gmaps: {
    textAlign: `center`,
    padding: `0 !important`,
    height: `500px`,
    width: `100%`,
    margin: `0 0 2rem`,
  },
  gmapsWrap: {
    margin: `0`,
    position: `relative`,
    '.gmapsLabel': {
      position: `absolute`,
      bottom: `0`,
      left: `0`,
    },
  },
  institutions: {
    maxWidth: `1400px`,
    margin: `1rem auto`,
  },
  attributes: {},
  attrHead: {
    backgroundColor: `#f76e26`,
    color: `#fff`,
    textAlign: `center`,
    textTransform: `uppercase`,
    padding: `1rem`,
  },
  attrContent: {
    display: `block`,
    textAlign: `center`,
    border: `1px solid #f76e26`,
    padding: `1rem`,
  },

  institutionsContent: {
    '@media (max-width: 959px)': {
      padding: `1rem !important`,
    },
    '@media (min-width: 960px)': {
      padding: `0 0 1rem 3rem !important`,
    },
    '& h3, & p': {
      color: `#a3a3a3`,
    },
    '& h2': {
      color: `#151b74`,
      textTransform: `uppercase`,
      margin: `1rem 0`,
      fontSize: `1.3rem`,
    },

    '& p': {
      lineHeight: `1.7`,
    },
  },
  contentTitle: {
    '& h1': {
      color: `#3289c9`,
      fontSize: `3rem`,
      margin: `0 0 1rem`,
    },
  },
  contentProceduri: {
    '& h3': {
      color: `#151b74`,
      margin: `1rem 0`,
      fontSize: `1.1rem`,
    },
  },
}));

export default InstitutionEntryStyle;
