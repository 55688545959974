import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { MEDIA_PATH } from '../../../Utils';

const useStyles = makeStyles((theme) => ({
  root: { padding: `0 !important` },
  institutionsGrid: {
    '& *': {
      fontFamily: `Arial`,
    },

    padding: 0,
  },
  institutionPicture: {
    width: `100%`,
    margin: `1rem`,
  },
  institutionContent: {
    margin: `1rem 2rem`,

    '& .excerpt': {
      fontSize: `1.2rem`,
      color: `#777`,
    },
    '& h4': {
      fontSize: `1.5rem`,
    },
    '& h5': {
      fontSize: `1rem`,
    },
    '& h4, & a': {
      display: `block`,
      margin: `1rem 0`,
      color: `#011b75`,
    },
  },
}));

const InstitutionsList = (props) => {
  const classes = useStyles();
  const { entries } = props;

  return (
    <div className={classes.institutionsGrid}>
      <Grid container spacing={4}>
        {entries &&
          entries.map((entry) => {
            return (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
                key={`list-item-${entry.id}`}
                className={classes.root}
              >
                <Grid container>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={12}
                    key={`list-item-image-${entry.id}`}
                  >
                    <a href={`/institutii/${entry.slug}`}>
                      <img
                        alt="Product"
                        className={classes.institutionPicture}
                        src={`${MEDIA_PATH}/${entry.pic_id_from_media_library}`}
                      />
                    </a>
                  </Grid>
                  <Grid
                    item
                    lg={7}
                    md={7}
                    xl={8}
                    xs={12}
                    // justify="flex-end"
                    key={`list-item-excerpt-${entry.id}`}
                    className={classes.institutionContent}
                  >
                    <h4>
                      <a href={`/institutii/${entry.slug}`}>{entry.title}</a>
                    </h4>
                    <h5>
                      <a href={`/institutii/${entry.slug}`}>
                        {entry.domeniu_activitate}
                      </a>
                    </h5>
                    {/*<p className={`excerpt`}>*/}
                    {/*  {getExcerptList(entry.procedura_1)}*/}
                    {/*</p>*/}
                    <p>
                      <a href={`/institutii/${entry.slug}`}>afla mai mult</a>
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default InstitutionsList;
