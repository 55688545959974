import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import MediaCard from './Card.component';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  content: {
    padding: 0,
  },
  image: {
    height: 48,
    width: 48,
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));
const docIcons = ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'];
const isDoc = (file) => docIcons.includes(file);

const FileIndex = (props) => {
  const { className, media, onDelete, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        subtitle={`${props.media.length} in total`}
        title="Media Files"
      />
      <Divider />
      <CardContent className={classes.content}>
        <Grid container className={classes.root} spacing={2}>
          {props.media.map((product, i) => {
            return (
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <MediaCard
                  product={product}
                  index={i}
                  isDoc={isDoc}
                  media={media}
                  onDelete={onDelete}
                />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FileIndex;
