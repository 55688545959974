import InstitutionsGmapsEntry from '@Components/Gmaps/InstitutionsGmapsEntry';
import { getHistoryPathArray } from '@Helpers';
import { Grid } from '@material-ui/core';
import { REST, rURL } from '@Utils';
import React, { useEffect, useState } from 'react';
import TestimonialsPartial from '../Testimonials/TestimonialsPartial';
import InstitutionEntryStyle from './entry.style';

const InstitutionEntry = (props) => {
  const classes = InstitutionEntryStyle();

  const { history } = props;

  const [entry, setEntry] = useState(null);
  // eslint-disable-next-line
  const [county, setCounty] = useState(null);

  useEffect(() => {
    const entrySlug = getHistoryPathArray(history);
    loadEntry(entrySlug.slice(-1));
    // eslint-disable-next-line
  }, []);

  const attrTitles = (ii) => {
    const attrHeads = [
      `Dimensiune`,
      `VALOAREA CONTRACTELOR <br /> DE ACHIZIȚIE PUBLICĂ`,
      `NUMĂRULUL CONTRACTELOR <br /> DE ACHIZIȚIE PUBLICĂ`,
      `NUMĂRUL PROCEDURILOR <br /> DE ACHIZIȚIE PUBLICĂ`,
      `DURATA MEDIE A PROCEDURII <br /> DE ACHIZIȚIE PUBLICĂ`,
      `NUMĂRUL MEDIU DE OFERTE PRIMITE`,
      // `Numar mediu contestatii / <br /> procedura`,
    ];

    return {
      __html: attrHeads[ii],
    };
  };

  const loadEntry = async (entrySlug) => {
    const answer = await REST.get(`${rURL}/public/institutions/${entrySlug}`);
    const {
      title,
      slug,
      lat,
      lng,
      pic_id_from_media_library,
      county_id,
      city_id,
      city_name,
      address_line_1,
      valoare_contracte_incheiate,
      numar_achizitii_publice,
      numar_proceduri_anulate,
      durata_procedura_achizitii,
      numar_mediu_oferte,
      numar_mediu_contestatii,
      domeniu_activitate,
      procedura_1,
      procedura_2,
      procedura_3,
      dimensiune,
      // siruta,
    } = answer.data.data;

    setEntry({
      title,
      slug,
      lat,
      lng,
      pic_id_from_media_library,
      county_id,
      city_id,
      city_name,
      address_line_1,
      valoare_contracte_incheiate,
      numar_achizitii_publice,
      numar_proceduri_anulate,
      durata_procedura_achizitii,
      numar_mediu_oferte,
      numar_mediu_contestatii,
      domeniu_activitate,
      procedura_1,
      procedura_2,
      procedura_3,
      dimensiune,
      // siruta,
    });
    getCountyById(county_id);
  };

  const getCountyById = async (countyId) => {
    const answer = await REST.get(`${rURL}/public/getCountyById/${countyId}`);
    setCounty(answer.data.data);
  };

  const getAttrTableColorCode = () => {
    let color = '';
    switch (entry.dimensiune) {
      case `mare`:
        color = '#21245d';
        break;
      case 'medie':
        color = '#3289c9';
        break;
      case 'mica':
        color = '#65b2e3';
        break;
      default:
        color = '#f76e26';
        break;
    }

    return color;
  };

  return (
    <div className={classes.root}>
      {entry && (
        <div>
          <Grid container spacing={4} className={classes.gmaps}>
            <Grid item lg={12} md={12} xl={12} xs={12} className={classes.root}>
              <div className={classes.gmapsWrap}>
                <InstitutionsGmapsEntry entry={entry} />
              </div>
            </Grid>
          </Grid>

          <div className={classes.institutions}>
            <Grid container spacing={4} style={{ width: `100%`, margin: 0 }}>
              <Grid item lg={3} md={3} xl={3} xs={12} className={classes.root}>
                <div className={classes.attributes}>
                  {[
                    entry.dimensiune,
                    entry.valoare_contracte_incheiate,
                    entry.numar_achizitii_publice,
                    entry.numar_proceduri_anulate,
                    entry.durata_procedura_achizitii,
                    entry.numar_mediu_oferte,
                  ].map((attr, ii) => {
                    return (
                      <div key={`attr-${ii}`}>
                        <div
                          className={classes.attrHead}
                          dangerouslySetInnerHTML={attrTitles(ii)}
                          style={{ backgroundColor: getAttrTableColorCode() }}
                        />
                        <div
                          className={classes.attrContent}
                          style={{
                            border: `1px solid ${getAttrTableColorCode()}`,
                          }}
                        >
                          {attr && attr.toUpperCase()}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Grid>

              <Grid
                item
                lg={7}
                md={7}
                xl={8}
                xs={12}
                className={classes.institutionsContent}
              >
                <div>
                  <div className={classes.contentTitle}>
                    <h1>{entry.title || entry.city_name}</h1>
                    <p>{entry.address_line_1}</p>
                  </div>

                  <h2>Domeniu de activitate</h2>
                  <h4>{entry.domeniu_activitate}</h4>

                  <h2>
                    ACHIZIţII ASISTATE DE EXPERţII BăNCII MONDIALE şI ANAP
                  </h2>

                  <div className={classes.contentProceduri}>
                    <h3>Procedura 1</h3>
                    <p>{entry.procedura_1}</p>
                    <h3>Procedura 2</h3>
                    <p>{entry.procedura_2}</p>
                    <h3>Procedura 3</h3>
                    <p>{entry.procedura_3}</p>
                  </div>
                </div>

                <TestimonialsPartial />
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

export default InstitutionEntry;
