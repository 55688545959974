import {
  Button, Grid, IconButton,
  TextField, Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import validate from 'validate.js';
import SignInStyle from './SignIn.style';

const SignIn = (props) => {
  const { history } = props;

  const classes = SignInStyle();

  const [error, setError] = useState({ message: null });
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const schema = {
    email: {
      presence: { allowEmpty: false, message: 'is required' },
      email: true,
      length: {
        maximum: 64,
      },
    },
    password: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 128,
      },
    },
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    // eslint-disable-next-line 
  }, [formState.values]);

  const handleBack = () => {
    history.push('/');
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  // const makeUser = async () => {
  //   const result = await REST.get(`${rURL}/auth/make`);
  // };

  const handleSignIn = async (event) => {
    event.preventDefault();
    // makeUser();
    if (formState.isValid) {
      // console.log('here');
      if (formState.values.email === 'alexandru.okros@wtzconsult.com' && formState.values.password === 'Parola123#') {
        history.push('/admin');
        window.location.reload();
      } else {
        setError({
          message: 'Login credentials are invalid',
        });
      }

      // axios
      //   .post(`${rURL}/auth/login`, formState.values)
      //   .then((response) => {
      //     setError({ message: null });
      //
      //     const { accessToken } = response.data;
      //
      //     if (accessToken) {
      //       localStorage.setItem('user', JSON.stringify(accessToken));
      //
      //       history.push('/admin');
      //       window.location.reload();
      //     }
      //
      //     return response.data;
      //   })
      //   .catch((err) => {
      //     const { error } = err.response.data;
      //     setError({
      //       message: error.message,
      //     });
      //   });
    } else {
      setError({ message: null });
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={12} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Typography className={classes.title} variant="h2">
                  Sign in
                </Typography>

                {error && error.message && (
                  <div className={classes.error}>
                    <div>{error.message}</div>
                  </div>
                )}

                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  to={'media'}
                >
                  Sign in
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SignIn);
