import { makeStyles } from '@material-ui/styles';
import { MEDIA_PATH } from '../../../Utils';

const AboutStyle = makeStyles((theme) => ({
  root: {
    maxWidth: `1400px`,
    margin: `3rem auto !important`,
    width: `90%`,
    lineHeight: 2,
    '& *': {
      fontFamily: `Arial`,
    },
    '& h1': {
      fontSize: `2rem`,
      color: `#151b74`,
      margin: `2rem 0`,
      textTransform: `uppercase`,
    },
    '& ul': { padding: `0 20px` },

    '& p': {
      lineHeight: 1.7,
      margin: `1.7rem 0`,
    },
  },
  noPadding: {
    padding: `0 !important`,
  },
  gmaps: (props) => ({
    backgroundImage: `url(${MEDIA_PATH}/${props.headerImg}`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    textAlign: `center`,
    padding: `0 !important`,
    height: `250px`,
    width: `100%`,
    margin: `0 0 2rem`,
  }),
  gigi: {
    backgroundPosition: `center center`,
  },
  gmapsWrap: (props) => ({
    margin: `0`,
    position: `relative`,
    '.gmapsLabel': {
      position: `absolute`,
      bottom: `0`,
      left: `0`,
    },
  }),
}));

export default AboutStyle;
