import React from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';

const mapStyles = {
  width: '500px',
  height: '500px',
};

const MapContainer = (props) => {
  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    props.updateLatLng(lat, lng);
  };

  return (
    <Map
      google={props.google}
      zoom={8}
      style={mapStyles}
      initialCenter={{
        lat: props.lat || 45.9317763,
        lng: props.long || 25.9817005,
      }}
    >
      <Marker
        title="Location"
        id={1}
        position={{
          lat: props.lat || 45.6923777,
          lng: props.lng || 25.8182983,
        }}
        draggable={true}
        onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
      >
        <InfoWindow visible={true}>
          <div>
            <p>
              Click on the map or drag the marker to select location where the
              incident occurred
            </p>
          </div>
        </InfoWindow>
      </Marker>
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAXVt-aqMsxEQUfQREywbBReHh2q3nfwBc',
})(MapContainer);
