
import { MEDIA_PATH, REST, rURL } from '@Utils';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import BeforeBlockquote from '../../../Assets/Images/1.svg';
import AfterBlockquote from '../../../Assets/Images/2.svg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const useStyles = makeStyles((theme) => ({
  testimonialsContent: {
    '& h2': {
      textTransform: `capitalize`,
      fontSize: `2rem`,
    },
    margin: `10rem 0 3rem !important`,
    position: `relative`,
  },
  testimonialsBeforeBlockquote: {
    background: `url(${BeforeBlockquote})`,
    height: `200px`,
    backgroundSize: `100%`,
    backgroundRepeat: `no-repeat`,
    width: `250px`,
    position: `absolute`,
    top: `-120px`,
    left: 0,
    zIndex: -1,
  },
  testimonialsAfterBlockquote: {
    background: `url(${AfterBlockquote})`,
    height: `200px`,
    backgroundSize: `100%`,
    backgroundRepeat: `no-repeat`,
    width: `250px`,
    position: `absolute`,
    bottom: `-200px`,
    right: 0,
    zIndex: -1,
  },

  testimonialAvatar: {
    '& img': {
      borderRadius: `100px`,
      // maxWidth: `200px`,
      width: 200,
      height: 250
    },
  },
  testimonialWrap: {
    // position: `relative`,
    // left: `1rem`,
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,

    justifyContent: "space-between",

    '@media (max-width: 960px)': {
      width: '100%',
      height: '100%',
    },
  },
  testimonialEntryContainer: { height: '100%' },
  testimonialEntry: {
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    height: '100%',
    alignSelf: 'center',
    marginLeft: '10%',

    '@media (max-width: 960px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  testimonialText: {
    fontStyle: 'italic'
  },
  testimonialName: {
    // position: `absolute`,
    // bottom: `-30px`,
    color: `#151b74 !important`,

  },
  testimonialPersonInfo: {
    marginTop: 20,
  }
}));

const TestimonialsPartial = (props) => {
  const classes = useStyles();

  const [testimonials, setTestimonials] = useState(null);

  useEffect(() => {
    loadTestimonials();
  }, []);

  const loadTestimonials = async () => {
    const answer = await REST.get(`${rURL}/public/getTestimonialsList`);
    setTestimonials(answer.data.data);
    // console.log("testimonials item >>>", answer.data.data)
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className={classes.testimonialsContent}>
      <div className={classes.testimonialsBeforeBlockquote} />
      <div className={classes.testimonialsAfterBlockquote} />
      <h2>Testimoniale</h2>
      <div className={classes.testimonialEntryContainer}>
        {testimonials && testimonials.length > 0 && (
          <Carousel
            responsive={responsive}
            autoPlay
            infinite
            autoPlaySpeed={10000}
            removeArrowOnDeviceType={['tablet', 'mobile']}
            showDots={false}
          >
            {testimonials.map((item) => {
              return item ? (
                <div className={classes.testimonialEntry}>
                  {/* <div className={classes.testimonialAvatar}>
                    <img
                      src={`${MEDIA_PATH}/${item.pic_id_from_media_library}`}
                      alt={`${item.name}`}
                      className={classes.imgAvatar}
                    />
                  </div> */}
                  <div className={classes.testimonialWrap}>
                    <p className={classes.testimonialText}>
                      {item.content}
                    </p>
                    <div className={classes.testimonialPersonInfo}>
                      <p className={classes.testimonialName}>
                        {item.name}
                      </p>
                      <p className={classes.testimonialName}>
                        {item.functie}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null;
            })}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default TestimonialsPartial;
