// eslint-disable-next-line
/* global google */
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React, { Component } from 'react';
import iconMarker from '../../Assets/Images/pin1.svg';

const mapStyles = {
  // width: '100%',
  height: '500px',
  margin: `0`,
  padding: `0`,
};

export class InstitutionsGmapsEntry extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="map-container">
        <Map
          style={mapStyles}
          google={this.props.google}
          className={'map'}
          initialCenter={{
            lat: this.props.entry.lat,
            lng: this.props.entry.lng,
          }}
          zoom={11}
        >
          <Marker
            icon={iconMarker}
            position={{ lat: this.props.entry.lat, lng: this.props.entry.lng }}
          />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: '',
})(InstitutionsGmapsEntry);
