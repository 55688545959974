import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useState } from 'react';
import validate from 'validate.js';
import { REST, rURL } from '../../../Utils/index';
import schema from './schema';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    padding: theme.spacing(4),
    width: `100%`,
  },
  grid: {
    height: '100%',
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    alignItems: 'center',
  },

  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },

  dropzone: {
    border: '2px solid #e3e3e3',
    borderRadius: 10,
    textAlign: 'center',
    margin: `0 0 20px`,
    fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif`,
  },

  dropParagraph: {
    padding: '40px',
  },

  fontFamily: {
    fontFamily: 'Arial',
  },
}));

const AdminTestimonialsAdd = (props) => {
  const classes = useStyles();
  const { history } = props;

  const [auth, setAuth] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    setAuth(true);
    loadEntry();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const loadEntry = async () => {
    const answer = await REST.get(`${rURL}/admin/getAbout`);

    const { content } = answer.data.data;

    setFormState((formState) => ({
      values: {
        content,
      },
      touched: {
        content: true,
      },
      isValid: true,
      errors: {},
    }));
  };

  const handleChange = (content, editor) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        content,
      },
      touched: {
        content: true,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formState.isValid) {
      // eslint-disable-next-line
      const result = await REST.post(
        `${rURL}/admin/update-about`,
        formState.values
      );

      history.push('/admin/about');
    }
  };

  // eslint-disable-next-line
  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      {auth && (
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <h1 className={classes.fontFamily}>Pagina About</h1>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <React.Fragment>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Card className={classes.root}>
                    <CardContent>
                      <div className={classes.details}>
                        <div>
                          <Typography gutterBottom variant="h2">
                            Continut
                          </Typography>
                        </div>
                      </div>

                      <Editor
                        value={formState.values.content || ''}
                        id={'about-tiny'}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | ' +
                            'alignleft aligncenter alignright alignjustify | ' +
                            'bullist numlist outdent indent | removeformat | help',
                        }}
                        onEditorChange={handleChange}
                        key="03cw0gerdgyn5l5bbl8t5kjzugli15q6enn4u4qw4r05ybn4"
                      />
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Button
                        className={classes.signInButton}
                        color="primary"
                        disabled={!formState.isValid}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        to={'media'}
                      >
                        Salveaza
                      </Button>
                    </CardActions>
                  </Card>
                </form>
              </div>
            </React.Fragment>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AdminTestimonialsAdd;
