import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { REST, rURL } from '@Utils';
import React, { useEffect, useState } from 'react';
import validate from 'validate.js';
import ImgFooter from '../../../../Assets/Images/img_footer.svg';
import useStyles from './Footer.style';
import schema from './schema';

const Footer = (props) => {
  // eslint-disable-next-line
  const { className, history } = props;

  const classes = useStyles();

  const [sent, setSent] = useState(null);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let url;

    if (formState.isValid) {
      url = `${rURL}/public/send-contact`;

      const result = await REST.post(url, formState.values);

      if (result.data.data.success === true) setSent(true);
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <footer className={classes.root}>
      <div className={classes.svg} />
      <div className={classes.formWrap}>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} xl={6} xs={12} className={classes.form}>
            {sent === true && (
              <div>
                <h2>Hai să vorbim!</h2>
                <h4>
                  Multumim pentru mesaj! Vom raspunde in cel mai scurt timp!
                </h4>
              </div>
            )}
            {!sent && (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardContent>
                    <div className={classes.details}>
                      <div>
                        <Typography gutterBottom variant="h2">
                        Hai să vorbim!
                        </Typography>
                      </div>
                    </div>

                    <TextField
                      className={classes.textField}
                      error={hasError('name')}
                      fullWidth
                      helperText={
                        hasError('name') ? formState.errors.name[0] : null
                      }
                      label={'Numele'}
                      name="name"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.name || ''}
                      variant="outlined"
                    />

                    <TextField
                      className={classes.textField}
                      error={hasError('email')}
                      fullWidth
                      helperText={
                        hasError('email') ? formState.errors.email[0] : null
                      }
                      label={'Email'}
                      name="email"
                      onChange={handleChange}
                      type="email"
                      value={formState.values.email || ''}
                      variant="outlined"
                    />

                    <TextField
                      multiline
                      rows={10}
                      aria-multiline={true}
                      className={classes.textField}
                      error={hasError('content')}
                      fullWidth
                      helperText={
                        hasError('content') ? formState.errors.content[0] : null
                      }
                      label="Conținut"
                      name="content"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.content || ''}
                      variant="outlined"
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      disabled={!formState.isValid}
                      size="large"
                      type="submit"
                      variant="contained"
                      to={'media'}
                    >
                      Trimite
                    </Button>
                  </CardActions>
                </Card>
              </form>
            )}
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            xl={6}
            xs={12}
            className={classes.formVisual}
          >
            <img src={`${ImgFooter}`} alt={`Chart Visual`} />
          </Grid>
        </Grid>

        <p className={classes.footerCopyright}>
          &copy; 2021 Agenția Națională pentru Achiziții Publice
        </p>
      </div>
    </footer>
  );
};

export default Footer;
