const schema = {
  title: {
    presence: { allowEmpty: false, message: 'is required' },
  },

  content: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

export default schema;
