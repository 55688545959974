import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';


const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'left',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const AdminInstitutionsToolbar = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        <Button
          href={'/admin/homepage-widgets/add'}
          color="primary"
          variant="contained"
        >
          Adauga Cartonas
        </Button>
      </div>
      {/*<div className={classes.row}>*/}
      {/*  <SearchInput*/}
      {/*    className={classes.searchInput}*/}
      {/*    placeholder="Search homepage widgets"*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};

AdminInstitutionsToolbar.propTypes = {
  className: PropTypes.string,
};

export default AdminInstitutionsToolbar;
