import { makeStyles } from '@material-ui/styles';
import { REST, rURL } from '@Utils';
import React, { useEffect, useRef, useState } from 'react';
import AdminInstitutionsToolbar from './AdminInstitutionsToolbar.component';
import InstitutionsIndex from './InstitutionsIndex.component';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },

  content: {
    marginTop: theme.spacing(2),
  },

  dropzone: {
    border: '2px solid #e3e3e3',
    borderRadius: 10,
    textAlign: 'center',
    // fontFamily: 'Arial',
    margin: `0 0 20px`,
    fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif`,
  },

  dropParagraph: {
    padding: '40px',
  },

  fontFamily: {
    fontFamily: 'Arial',
  },
}));

const AdminInstitutions = () => {
  const classes = useStyles();
  const [auth, setAuth] = useState(false);
  const [entriesList, setEntriesList] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  useEffect(() => {
    // UserService.hasToken().then(
    //   (response) => {
    setAuth(true);
    getInstitutionList();
    //   },
    //   (error) => {
    //     setAuth(false);
    //     window.location.href = '/login';
    //   }
    // );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getInstitutionList();
    // eslint-disable-next-line
  },[page, size])

  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      // console.log(entriesList);
    } else didMountRef.current = true;
  });

  const getInstitutionList = async () => {
    const newentriesList = await REST.get(`${rURL}/admin/getInstitutionList?page=${page}&size=${size}`);
    setEntriesList(newentriesList.data.data);
  };

  const onDelete = async (id) => {
    // eslint-disable-next-line
    const result = await REST.post(`${rURL}/admin/delete-institution`, {
      id,
    });
    getInstitutionList();
  };

  const onFilter = (rows) => {
    if (rows.data.length > 0) {
      setEntriesList(rows.data);
    }
  };

  return (
    <div className={classes.root}>
      {auth && (
        <div>
          <AdminInstitutionsToolbar onFilter={onFilter} />
          {entriesList.total > 0 && (
            <div className={classes.content}>
              <InstitutionsIndex entries={entriesList} onDelete={onDelete} setPage={setPage} setSize={setSize} page={page} size={size} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminInstitutions;
