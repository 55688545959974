const schema = {
  title: {
    presence: { allowEmpty: true, message: 'is required' },
  },

  address_line_1: {
    presence: { allowEmpty: true, message: 'is required' },
  },

  county_id: {
    presence: { allowEmpty: false, message: 'is required' },
  },

  city_id: {
    presence: { allowEmpty: false, message: 'is required' },
  },

  city_name: {
    presence: { allowEmpty: false, message: 'is required' },
  },

  valoare_contracte_incheiate: {
    presence: { allowEmpty: false, message: 'is required' },
  },

  numar_achizitii_publice: {
    presence: { allowEmpty: false, message: 'is required' },
  },

  numar_proceduri_anulate: {
    presence: { allowEmpty: false, message: 'is required' },
  },

  durata_procedura_achizitii: {
    presence: { allowEmpty: false, message: 'is required' },
  },

  numar_mediu_oferte: {
    presence: { allowEmpty: false, message: 'is required' },
  },

  // numar_mediu_contestatii: {
  //   presence: { allowEmpty: false, message: 'is required' },
  // },

  domeniu_activitate: {
    presence: { allowEmpty: false, message: 'is required' },
  },

  procedura_1: {
    presence: { allowEmpty: false, message: 'is required' },
  },

  procedura_2: {
    presence: { allowEmpty: false, message: 'is required' },
  },

  procedura_3: {
    presence: { allowEmpty: false, message: 'is required' },
  },

  // siruta: {
  //   presence: { allowEmpty: true, message: '' },
  // },

  dimensiune: {
    presence: { allowEmpty: true, message: '' },
  },
};

export default schema;
