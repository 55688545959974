import InstitutionsGmapsList from '@Components/Gmaps/InstitutionsGmapsList';
import { Grid } from '@material-ui/core';
import { REST, rURL } from '@Utils';
import React, { useEffect, useState } from 'react';
import IndexStyle from './index.style';
import InstitutionsGrid from './Institutions.grid.component';
import InstitutionsList from './Institutions.list.component';

const Institutions = () => {
  const classes = IndexStyle();
  const [display, setDisplay] = useState('grid');
  const [entries, setEntries] = useState(null);

  useEffect(() => {
    getInstitutionList();
  }, []);

  const getInstitutionList = async () => {
    const getList = await REST.get(`${rURL}/public/institutions`);
    setEntries(getList.data.data);
  };

  const onToggleDisplay = (type) => {
    setDisplay(type);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4} className={classes.gmaps}>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
          className={classes.noPadding}
        >
          <div className={classes.gmapsWrap}>
            <InstitutionsGmapsList entries={entries} />
          </div>
        </Grid>
      </Grid>

      <div className={classes.institutions}>
        <Grid container spacing={4} style={{ margin: `0` }}>
          <Grid
            item
            lg={11}
            md={10}
            xl={11}
            xs={7}
            className={classes.noPadding}
          >
            <h1>Autorități contractante</h1>
          </Grid>
          <Grid item lg={1} md={2} xl={1} xs={5} className={classes.noPadding}>
            <div className={classes.toggler}>
              {/* <a
                href="/#"
                className={display === `grid` && classes.activeToggledBtn}
                onClick={() => onToggleDisplay('grid')}
              >
                GRID
              </a> */}
              {/* <a
                href="/#"
                className={display === `list` && classes.activeToggledBtn}
                onClick={() => onToggleDisplay('list')}
              >
                LIST
              </a> */}
            </div>
          </Grid>
        </Grid>

        {display === `grid` && <InstitutionsGrid entries={entries} />}
        {display === `list` && <InstitutionsList entries={entries} />}
      </div>
    </div>
  );
};

export default Institutions;
