import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { REST, rURL } from '../../../../Utils/index';
// import FileIndex from './components/FileIndex.component';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },

  dropzone: {
    border: '2px solid #e3e3e3',
    borderRadius: 10,
    textAlign: 'center',
    // fontFamily: 'Arial',
    margin: `0 0 20px`,
    fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif`,
  },

  dropParagraph: {
    padding: '40px',
  },

  fontFamily: {
    fontFamily: 'Arial',
  },
}));

const Uploader = (props) => {
  const classes = useStyles();
  const { usedWith, fileType } = props;

  const [auth, setAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    setAuth(true);
    getFileList();
  }, []);

  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
    } else didMountRef.current = true;
  });

  const getFileList = async () => {
    const newFileList = await REST.get(`${rURL}/admin/getFileList`);
    setFileList(newFileList.data.data);
  };

  const onUpload = async (files) => {
    setIsLoading(true);
    const formData = new FormData();

    files.map((item) => {
      formData.append('photos', item);
      return null;
    });

    formData.append('usedWith', usedWith);
    formData.append('fileType', fileType);

    // eslint-disable-next-line
    const result = await REST.post(
      `${rURL}/admin/uploadGallery`,
      formData
    ).then((answer) => {
      if(answer.data.status === true) {
        setIsLoading(false);
        window.location = '/admin/rezultate';
      }
    });

  };


  return (
    <div className={classes.root}>
      {isLoading && <div>Loading ...</div>}
      {auth && (
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <React.Fragment>
              {fileType && (
                <Dropzone
                  onDrop={onUpload}
                  accept={
                    fileType.toLowerCase() === 'images'
                      ? '.jpeg,.jpg,.png'
                      : '.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf'
                  }
                  disabled={isLoading}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className={classes.dropzone}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className={classes.dropParagraph}>
                          Click sau Drag 'n' drop
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}

              {/*{fileList.length > 0 && (*/}
              {/*  <FileIndex media={fileList} onDelete={onDelete} />*/}
              {/*)}*/}
            </React.Fragment>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Uploader;
