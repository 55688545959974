import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import validate from 'validate.js';
import { REST, rURL } from '../../../Utils/index';
import HpgTitleStyle from './HpgTitle.style';
import schema from './schema';

const AdminHomepageTitle = (props) => {
  const classes = HpgTitleStyle();
  const { history } = props;

  const [auth, setAuth] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    setAuth(true);
    loadEntry();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const loadEntry = async () => {
    const answer = await REST.get(`${rURL}/admin/getHomepageTitle`);

    setFormState((formState) => ({
      values: {
        title: answer.data.data.title || '',
        content: answer.data.data.content || '',
      },
      touched: {
        title: true,
        content: true,
      },
      isValid: true,
      errors: {},
    }));
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  // eslint-disable-next-line
  const handleChangeContent = (content, editor) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        content,
      },
      touched: {
        content: true,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formState.isValid) {
      // eslint-disable-next-line
      const result = await REST.post(
        `${rURL}/admin/update-homepage-title`,
        formState.values
      );

      history.push('/admin/homepage-title');
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      {auth && (
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <h1 className={classes.fontFamily}>Homepage Principal</h1>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <React.Fragment>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Card className={classes.root}>
                    <CardContent>
                      <div className={classes.details}>
                        <div>
                          <Typography gutterBottom variant="h2">
                            Continut
                          </Typography>
                        </div>
                      </div>

                      <TextField
                        className={classes.textField}
                        error={hasError('title')}
                        fullWidth
                        helperText={
                          hasError('title') ? formState.errors.title[0] : null
                        }
                        label="Primul Titlu din Homepage"
                        name="title"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.title || ''}
                        variant="outlined"
                      />

                      <TextField
                        multiline
                        rows={10}
                        aria-multiline={true}
                        className={classes.textField}
                        error={hasError('title')}
                        fullWidth
                        helperText={
                          hasError('content')
                            ? formState.errors.content[0]
                            : null
                        }
                        label="Continut"
                        name="content"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.content || ''}
                        variant="outlined"
                      />

                      {/*<Editor*/}
                      {/*  initialValue={formState.values.content || ''}*/}
                      {/*  init={{*/}
                      {/*    height: 500,*/}
                      {/*    menubar: false,*/}
                      {/*    plugins: [*/}
                      {/*      'advlist autolink lists link image charmap print preview anchor',*/}
                      {/*      'searchreplace visualblocks code fullscreen',*/}
                      {/*      'insertdatetime media table paste code help wordcount',*/}
                      {/*    ],*/}
                      {/*    toolbar:*/}
                      {/*      'undo redo | formatselect | bold italic backcolor | \*/}
                      {/*      alignleft aligncenter alignright alignjustify | \*/}
                      {/*      bullist numlist outdent indent | removeformat | help',*/}
                      {/*  }}*/}
                      {/*  onEditorChange={handleChangeContent}*/}
                      {/*/>*/}
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Button
                        className={classes.signInButton}
                        color="primary"
                        disabled={
                          !formState.values.title || !formState.values.content
                        }
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        to={'media'}
                      >
                        Salveaza
                      </Button>
                    </CardActions>
                  </Card>
                </form>
              </div>
            </React.Fragment>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AdminHomepageTitle;
