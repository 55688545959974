import {
  Card, CardContent,
  CardHeader,
  Divider,
  Grid
} from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import { red } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { MEDIA_PATH } from '../../../../Utils';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '50%',
    width: '50%',
    overflowY: 'scroll',
    position: 'fixed',
    top: '20%',
    left: '20%',
    cursor: 'pointer',
  },
  content: {
    padding: `1rem`,
  },
  image: {
    // height: 48,
    width: '100%',
  },
  actions: {
    justifyContent: 'flex-end',
  },

  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const FileIndexPopup = (props) => {
  const { className, onFileSelect, ...rest } = props;

  const classes = useStyles();

  const onItemSelect = (id, filename) => {
    onFileSelect(id, filename);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        subtitle={`${props.media.length} in total`}
        title={`Alege o poza`}
      />
      <Divider />
      <CardContent className={classes.content}>
        <Grid container spacing={3}>
          {props.media.map((product, i) => {
            return (
              <Grid
                onClick={() => onItemSelect(product.id, product.filename)}
                item
                lg={4}
                md={4}
                xl={4}
                xs={12}
                key={product.id}
              >
                <Card key={`card-in-plm-${i}`}>
                  <CardHeader
                    title={product.orig_name}
                    subheader={`Urcat la data de ${moment(
                      product.created_at_timestamp
                    ).format('DD/MM/YYYY HH:mm')}`}
                  />
                  <a
                    href={`${MEDIA_PATH}/${product.filename}`}
                    target={`_blank`}
                  >
                    <CardMedia
                      className={classes.media}
                      image={`${MEDIA_PATH}/${product.filename}`}
                      title={product.orig_name}
                    />
                  </a>
                  <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                      <div>
                        <strong>Folosit la institutia:</strong>{' '}
                        {product.used_with_location_name}
                      </div>
                      <div>
                        <strong>Oras institutie:</strong> {product.used_with_city_name}
                      </div>
                      <div>
                        <strong>Institutia are adresa:</strong> {product.useed_with_address || '\\N'}
                      </div>
                    </Typography>
                  </CardContent>
                </Card>

                {/*<img*/}
                {/*  alt="Product"*/}
                {/*  className={classes.image}*/}
                {/*  src={`${MEDIA_PATH}/${product.filename}`}*/}
                {/*/>*/}
                {/*<br />*/}
                {/*<h4>{product.orig_name}</h4>*/}
                {/*<br />*/}
                {/*<h6>*/}
                {/*  last used for:{' '}*/}
                {/*  {` ${product.used_with_city_name || ''} ${*/}
                {/*    product.used_with_location_name || ''*/}
                {/*  } ${product.used_with_address || ''}`}*/}
                {/*</h6>*/}
                {/*<br />*/}
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FileIndexPopup;
