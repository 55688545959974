import React, { useEffect, useState } from 'react';
import InstitutionsGmapsList from '@Components/Gmaps/InstitutionsGmapsList';
import { REST, rURL } from '@Utils';

const IframeMap = () => {
  const [entries, setEntries] = useState(null);

  const getInstitutionList = async () => {
    await REST.get(`${rURL}/public/institutions`)
      .then((answer) => {
        setEntries(answer.data.data);
      })
      .catch((error) => {
        const { message } = error;
        console.log('ERR >>>', message);
      });
  };

  useEffect(() => {
    getInstitutionList();
    return () => {};
  }, []);

  return <InstitutionsGmapsList entries={entries} height={`100%`} />;
};

export default IframeMap;
