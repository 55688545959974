import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { SearchInput } from '@Components';
import { REST, rURL } from '../../../Utils/index';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'left',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const AdminInstitutionsToolbar = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const onChange = async (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (value.length > 2) {
      const filter = await REST.post(`${rURL}/admin/filter-experts`, {
        filter: value,
      });
      props.onFilter(filter.data);
    } else {
      const filter = await REST.get(`${rURL}/admin/getExpertsList`);
      props.onFilter(filter.data);
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        <Button
          href={'/admin/procese-achizitii/add'}
          color="primary"
          variant="contained"
        >
          Adauga proces
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Cauta procesul dupa nume"
          onChange={onChange}
        />
      </div>
    </div>
  );
};

AdminInstitutionsToolbar.propTypes = {
  className: PropTypes.string,
};

export default AdminInstitutionsToolbar;
