import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
// import UAParser from 'ua-parser-js';

import theme from '@Theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './Assets/scss/index.scss';
import validators from './Common/validators';
import Routes from './Routes.old';

const browserHistory = createBrowserHistory();

validate.validators = {
  ...validate.validators,
  ...validators,
};
// const parser = new UAParser();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </ThemeProvider>
  );
};

export default App;
