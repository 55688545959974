import { makeStyles } from '@material-ui/styles';
import { MEDIA_PATH } from '../../../Utils';
// import topbanner from '../../../Assets/Images/AssetBanner.png';
import topbanner from '../../../Assets/Images/Asset4x.png';

const RezultateStyle = makeStyles((theme) => ({
  root: {
    // height: '100%',
    // padding: theme.spacing(4),
    width: `100%`,
    backgroundColor: '#efefef',
  },

  cardContainer: {
    backgroundColor: '#fff',
  },
  grid: {
    height: '100%',
  },
  quote: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    marginTop: '-300px',
    maxWidth: '1500px',
  },

  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },

  dropzone: {
    border: '2px solid #e3e3e3',
    borderRadius: 10,
    textAlign: 'center',
    // fontFamily: 'Arial',
    margin: `0 0 20px`,
    fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif`,
  },

  dropParagraph: {
    padding: '40px',
  },

  fontFamily: {
    fontFamily: 'Arial',
  },

  galleryWrapper: {
    height: '180px',
    position: 'relative',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 30px',
    width: '100%',
    margin: '0 0 2rem 0',
    '@media (max-width: 959px)': {
      width: '100%',
      display: 'block',
    },
    '@media (min-width: 960px)': {
      height: '180px',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      overflowY: 'hidden',
    },
  },

  galleryItemPic: {
    width: '24.5%',
    display: 'inline-block',

    '& img': {
      maxHeight: '70%',
      margin: '.5rem auto',
      display: 'block',
    },

    '@media (max-width: 959px)': {
      width: '100%',
      display: 'block',
      margin: '0 0 10px 0',
    },
    '@media (min-width: 960px)': {
      width: '33.333%',
      display: 'inline-block',
    },

    backgroundColor: '#eee',
    float: 'none',
    height: '150px',
    padding: '1rem',
    margin: '0 0.25%',
    zoom: 1,
  },

  deleteButton: {
    margin: '0 auto',
    display: 'block',
    width: '40px',
    textAlign: 'center',
  },

  noPadding: {
    padding: `0 !important`,
    backgroundImage: `url(${topbanner})`,
  },
  formatted: {
    maxWidth: `1400px`,
    width: `90%`,
    lineHeight: 2,
    '& *': {
      fontFamily: `Arial`,
    },
    '& h1': {
      fontSize: `2rem`,
      color: `#151b74`,
      margin: `2rem 0`,
      textTransform: `uppercase`,
    },
    '& ul': { padding: `0 20px` },

    '& p': {
      lineHeight: 1.7,
      margin: `1.7rem 0`,
    },
  },

  gmaps: (props) => ({
    backgroundImage: `url(${MEDIA_PATH}/${props.headerImg}`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    textAlign: `center`,
    padding: `0 !important`,
    height: `500px`,
    width: `100%`,
    margin: `0 0 2rem`,
  }),
  gigi: {
    backgroundPosition: `center center`,
  },
  gmapsWrap: {
    margin: `0`,
    position: `relative`,
    '.gmapsLabel': {
      position: `absolute`,
      bottom: `0`,
      left: `0`,
    },
  },
  testimonialsContent: {
    width: `90%`,
    maxWidth: `1400px`,
    margin: `3rem auto !important`,
    '& *': {
      fontFamily: `Arial`,
    },
    '& h1': {
      fontSize: `2rem`,
      color: `#151b74`,
      margin: `2rem 0`,
      // textTransform: `uppercase`,
    },
    '& h2': {
      // textTransform: `capitalize`,
      fontSize: `2rem`,
    },
    position: `relative`,
  },

  testimonialitem: {
    padding: 0,
  },

  testimonialAvatar: {
    '& img': {
      borderRadius: `100px`,
      maxWidth: `200px`,
      margin: `0 auto`,
      textAlign: `center`,
      display: `block`,
    },
  },
  testimonialWrap: {
    position: `relative`,
    left: `2rem`,
  },
  testimonialEntry: {
    width: `100%`,
    '@media (min-width: 960px)': {
      margin: `0 0 6rem`,
    },
    '@media (max-width: 959px)': {
      margin: `0 0 1rem`,
    },
  },
  testimonialText: {
    maxWidth: `91%`,
    fontSize: `1.4rem`,
    lineHeight: `1.7`,
  },
  testimonialName: {
    margin: `1.5rem 0`,

    color: `#151b74 !important`,
    fontSize: `1.4rem`,
  },

  // FOR RIGHT SIDE -- WHERE SHORT DESCRIPTION AND DOCUMENTS
  leftSectionContentContainer: {},
  shortDescriptionContainer: {
    padding: '15px',
    color: '#04124f',
    textTransform: 'uppercase',
    height: '100px',
  },
  documentsContainer: {
    paddingTop: '20px',
    margin: '0 auto',
    // height: '595px',
    overflowY: 'auto',
    padding: '5px',
    backgroundColor: '#f1f5f8',
    marginBottom: 10,

    '& h3': {
      color: '#04124f',
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'start',
    },
  },
  galleryWrapperNew: {
    width: '100%',
    whiteSpace: 'nowrap',
    position: 'relative',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    // padding: '0px 30px',
    backgroundColor: '#f1f5f8',
  },
  galleryItemPicNew: {
    width: '100%',
    // padding: '0px 10px',
    // backgroundColor: '#eee',
    display: 'inline-block',
    textAlign: 'center',
    marginBottom: '15px',

    '& a': {
      color: 'black',
      fontWeight: 'bold',
    },
  },
  documentIcon: {
    height: '120px',
  },
  descriptionIcon: {
    // fontSize: '100px',
    width: '100px',
  },

  // FOR RIGHT SIDE -- WHERE PAGE DESCRIPTION
  rightSectionContentContainer: {
    paddingLeft: '30px',
  },
  contentSectionContainer: {
    marginLeft: '20px',
    fontSize: '17px',
    '& h3': {
      // color: `#77b3d4`,
      // fontSize: `30px`,
      margin: `0 0 1rem`,
    },
    marginTop: '30px',
  },
  rightSectionTitle: {
    '& h3': {
      color: `#77b3d4`,
      fontSize: `30px`,
      margin: `0 0 1rem`,
    },
  },
  rightSectionContent: {
    fontSize: '20px',
  },

  // FOR LIST CONTAINER

  listSectionTitle: {
    margin: '20px 0px 0px 15px',
    '& h3': {
      color: `#77b3d4`,
      fontSize: `30px`,
      margin: `0 0 1rem`,
    },
  },

  cardTitle: {
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    '& p': {
      color: `black`,
      fontSize: `27px`,
      fontWeight: 'bold',
    },
  },
  cardImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 10,
    marginTop: 10,
    paddingBottom: 5,
    border: '1px solid #d3d3d3',
  },
  cardImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  formattedHtml: {
    '& li ': {
      margin: '10px 0px 10px 20px',
    },
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 100,
  },

  heroPng: {
    height: '700px',
    width: '100%',
    position: 'relative',
    left: 0,
    backgroundColor: 'Red',
    backgroundImage: `url(${topbanner})`,
  },

  imageSliderEntryTab: {},
  titleContainer: {
    marginLeft: '20px',
    '& h2': {
      color: `#77b3d4`,
      fontSize: `30px`,
    },
  },

  listContainer: {
    // display: 'flex',
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    // marginBottom: '50px',
  },

  tabsContainer: {
    width: '100%',
  },

  listCard: {
    // cursor: 'pointer',
    // flex: '1 0 33%',
  },

  interviewName: {
    color: '#0377fc',
    margin: 10,
  },

  tabTitle: {
    color: 'black',
    fontSize: '22px',
    fontWeight: 'bold',
    margin: '5px',
    marginLeft: 10,
  },

  more: {
    color: '#0377fc',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px',
    cursor: 'pointer',
  },
}));

export default RezultateStyle;
