import { Card } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import { red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { IMG_PATH, MEDIA_PATH } from '@Utils';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: `100%`,
    margin: 20
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const MediaCard = (props) => {
  const { product, isDoc, onDelete } = props;
  const classes = useStyles();
  const extension = product.filename.split('.').pop();

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <IconButton aria-label="add to favorites">
            <DeleteIcon onClick={() => onDelete(product.filename)} />
          </IconButton>
        }
        title={product.orig_name}
        subheader={`Urcat la data de ${moment(
          product.created_at_timestamp
        ).format('DD/MM/YYYY HH:mm')}`}
      />
      <a href={`${MEDIA_PATH}/${product.filename}`} target={`_blank`}>
        <CardMedia
          className={classes.media}
          image={`${
            isDoc(extension)
              ? IMG_PATH + '/doc_svg.png'
              : MEDIA_PATH + '/' + product.filename
          }`}
          title={product.orig_name}
        />
      </a>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          <p>
            <strong>Folosit la institutia:</strong>{' '}
            {product.used_with_location_name}
          </p>
          <p>
            <strong>Oras institutie:</strong> {product.used_with_city_name}
          </p>
          <p>
            <strong>Institutia are adresa:</strong> {product.useed_with_address || '\\N'}
          </p>
        </Typography>
      </CardContent>
      <CardActions disableSpacing>

      </CardActions>
    </Card>
  );
};

export default MediaCard;
