import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// import Slider from 'react-slick';
// import sliderSettings from './sliderSettings';
// import Carousel from 'react-elastic-carousel';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { REST, rURL } from '../../../Utils';
import InstitutionsGmapsList from '@Components/Gmaps/InstitutionsGmapsList';
import useStyles from './style';
import TestimonialsPartialHorizontal from '../Testimonials/TestimonialsPartialHorizontal';

const Homepage = () => {
  const classes = useStyles();

  const [entries, setEntries] = useState(null);
  const [widgets, setWidgets] = useState(null);
  const [hompageTitle, setHomepageTitle] = useState(null);
  const [homepageIntermezzo, setHomepageIntermezzo] = useState(null);

  useEffect(() => {
    getInstitutionList();
    getWidgetsList();
    getHomepageTitle();
    getHomepageIntermezzo();
  }, []);

  const getInstitutionList = async () => {
    const getList = await REST.get(`${rURL}/public/institutions`);
    setEntries(getList.data.data);
  };

  const getWidgetsList = async () => {
    const getList = await REST.get(`${rURL}/public/getWidgetsList`);
    setWidgets(getList.data.data);
  };

  const getHomepageTitle = async () => {
    const getHomepageTitle = await REST.get(`${rURL}/public/getHomepageTitle`);
    setHomepageTitle(getHomepageTitle.data.data);
  };

  const getHomepageIntermezzo = async () => {
    const getHomepageIntermezzo = await REST.get(
      `${rURL}/public/getHomepageIntermezzo`
    );
    setHomepageIntermezzo(getHomepageIntermezzo.data.data);
  };

  return (
    <div>
      <div className={classes.preSlider}>
        <div className={classes.root}>
          <h1>{hompageTitle && hompageTitle.title}</h1>
          <h2>{hompageTitle && hompageTitle.content}</h2>
          <Button
            className={classes.confirmYes}
            color="primary"
            size="large"
            variant="contained"
            href={`./despre-proiect`}
          >
            Vezi detalii
          </Button>
        </div>
      </div>
      {widgets && (
        <div className={classes.sliderContainer}>
          <Carousel
            // infinite
            slidesPerPage={10}
            arrows={false}
            breakpoints={{
              640: {
                slidesPerPage: 1,
                arrows: false,
              },
              900: {
                slidesPerPage: 8,
                arrows: false,
              },
            }}
          >
            {widgets.map((item) => {
              return (
                <div
                  key={`slider-key-${item.id}`}
                  className={classes.slideItemWrapper}
                >
                  <div className={classes.slideItemNumber}>
                    {item.show_this_number}
                  </div>
                  <div className={classes.slideItemContent}>{item.content}</div>
                </div>
              );
            })}
          </Carousel>

          {/*<Slider {...sliderSettings}>*/}

          {/*</Slider>*/}
        </div>
      )}
      <Grid container spacing={4} className={classes.gmaps}>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
          className={classes.noPadding}
        >
          <div className={classes.gmapsWrap}>
            <InstitutionsGmapsList entries={entries} />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.afterSlider}>
        <Grid
          item
          lg={6}
          md={6}
          xl={6}
          xs={12}
          className={classes.afterSliderLeft}
        ></Grid>
        <Grid
          item
          lg={6}
          md={6}
          xl={6}
          xs={12}
          className={classes.afterSliderRight}
        >
          <div className={classes.afterSliderRightContent}>
            <h1>{homepageIntermezzo && homepageIntermezzo.title}</h1>
            <h2>{homepageIntermezzo && homepageIntermezzo.content}</h2>

            <Button
              className={classes.experts}
              color="primary"
              size="large"
              variant="contained"
              href={`./rezultate/bune-practici`}
            >
              Vezi Rezultate
            </Button>

          </div>
        </Grid>
      </Grid>

      <Grid container spacing={4} className={classes.afterSlider}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <div style={{ margin: '2rem auto', width: '80%', height: '100%' }}>
            <iframe
              title="iframe"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/JcZf9bg6gxc"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Grid>
      </Grid>

      {/*<div className={classes.root}>*/}
      <TestimonialsPartialHorizontal />
      {/*</div>*/}
    </div>
  );
};

export default Homepage;
