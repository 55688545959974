import {
  Card,
  CardActions,
  CardContent, Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  content: {
    padding: 0,
  },
  image: {
    height: 48,
    width: 48,
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const TestimonialsIndex = (props) => {
  const { className, entries, ...rest } = props;

  const classes = useStyles();

  const [selectedentries, setSelectedentries] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  // eslint-disable-next-line
  const handleSelectAll = (event) => {
    const { entries } = props;

    let selectedentries;

    if (event.target.checked) {
      selectedentries = entries.map((entry) => entry.id);
    } else {
      selectedentries = [];
    }

    setSelectedentries(selectedentries);
  };

  // eslint-disable-next-line
  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedentries.indexOf(id);
    let newSelectedentries = [];

    if (selectedIndex === -1) {
      newSelectedentries = newSelectedentries.concat(selectedentries, id);
    } else if (selectedIndex === 0) {
      newSelectedentries = newSelectedentries.concat(selectedentries.slice(1));
    } else if (selectedIndex === selectedentries.length - 1) {
      newSelectedentries = newSelectedentries.concat(
        selectedentries.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedentries = newSelectedentries.concat(
        selectedentries.slice(0, selectedIndex),
        selectedentries.slice(selectedIndex + 1)
      );
    }

    setSelectedentries(newSelectedentries);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  {/*<TableCell padding="checkbox">*/}
                  {/*  <Checkbox*/}
                  {/*    checked={selectedentries.length === entries.length}*/}
                  {/*    color="primary"*/}
                  {/*    indeterminate={*/}
                  {/*      selectedentries.length > 0 &&*/}
                  {/*      selectedentries.length < entries.length*/}
                  {/*    }*/}
                  {/*    onChange={handleSelectAll}*/}
                  {/*  />*/}
                  {/*</TableCell>*/}
                  <TableCell>Nume</TableCell>
                  <TableCell>Continut</TableCell>
                  <TableCell>Email</TableCell>
                  {/*<TableCell>Delete</TableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {entries.slice(0, rowsPerPage).map((entry) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={entry.id}
                    selected={selectedentries.indexOf(entry.id) !== -1}
                  >
                    {/*<TableCell padding="checkbox">*/}
                    {/*  <Checkbox*/}
                    {/*    checked={selectedentries.indexOf(entry.id) !== -1}*/}
                    {/*    color="primary"*/}
                    {/*    onChange={(event) => handleSelectOne(event, entry.id)}*/}
                    {/*    value="true"*/}
                    {/*  />*/}
                    {/*</TableCell>*/}
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{entry.name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell className={classes.nameContainer}>
                      <Typography variant="body1">{entry.content}</Typography>
                    </TableCell>
                    <TableCell>{entry.email}</TableCell>
                    {/*<TableCell>*/}
                    {/*  <IconButton edge="end" size="small">*/}
                    {/*    <DeleteIcon onClick={() => props.onDelete(entry.id)} />*/}
                    {/*  </IconButton>*/}
                    {/*</TableCell>*/}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={entries.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default TestimonialsIndex;
