import { MEDIA_PATH, REST, rURL } from '@Utils';
import React, { useEffect, useState } from 'react';
import TestimonialPartialStyle from './TestimonialPartial.style';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const TestimonialsPartialHorizontal = (props) => {
  const classes = TestimonialPartialStyle();

  const [testimonials, setTestimonials] = useState(null);

  useEffect(() => {
    loadTestimonials();
  }, []);

  const loadTestimonials = async () => {
    const answer = await REST.get(`${rURL}/public/getTestimonialsList`);
    setTestimonials(answer.data.data);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={classes.testimonialsContent}>
      <div className={classes.testimonialsBeforeBlockquote} />
      <div className={classes.testimonialsAfterBlockquote} />

      {testimonials && (
        <div>
          <h2>Testimoniale</h2>
          <div className={classes.testimonialEntry}>
            {testimonials && testimonials.length > 0 && (
              <Carousel
                responsive={responsive}
                autoPlay
                infinite
                autoPlaySpeed={10000}
                removeArrowOnDeviceType={['tablet', 'mobile']}
                showDots={false}
              >
                {testimonials.map((item) => {
                  return item ? (
                    <div
                      key={`grid-test-${item.id}`}
                      className={classes.testimonialItem}
                    >
                      {/* <div className={classes.testimonialAvatar}>
                        <img
                          src={`${MEDIA_PATH}/${item.pic_id_from_media_library}`}
                          alt={`${item.name}`}
                        />
                      </div> */}
                      <div className={classes.testimonialWrap}>
                        <p className={classes.testimonialText}>
                          {item.content}
                        </p>
                        <p className={classes.testimonialName}>
                          {item.name} - {item.functie}
                        </p>
                      </div>
                    </div>
                  ) : null;
                })}
              </Carousel>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TestimonialsPartialHorizontal;
