import { makeStyles } from '@material-ui/styles';
import BgFooter from '../../../../Assets/Images/back_footer.svg';

const useStyles = makeStyles(() => ({
  root: {
    '& form .MuiPaper-root': {
      background: `none !important`,
    },
    '& .MuiInputBase-root': {
      border: `1px solid #fff !important`,
      borderRadius: `0`,
      background: `transparent`,
      color: `#fff`,
      margin: `1rem 0`,
    },
    '& .MuiInputLabel-outlined': {
      color: `#fff`,
    },

    '& .Mui-disabled': {
      background: `rgba(158, 158, 158, 1) !important`,
      color: `#333 !important`,
    },

    width: `100%`,
    backgroundImage: `url(${BgFooter})`,
    backgroundSize: `cover`,
    height: 0,
    padding: 0 /* reset */,
    paddingBottom: `calc(100% * 3 / 4)`,
    '@media (max-width: 959px)': {
      height: `100%`,
      // background: `linear-gradient(to right,  #2b4bb6 0%,#0c0957 100%)`,
      // filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='#2b4bb6', endColorstr='#0c0957',GradientType=1 )`,
    },
  },
  flexGrow: {
    flexGrow: 1,
    display: 'flex',
  },
  formWrap: {
    fontFamily: `Arial`,
    '& h2, h4': {
      color: `#fff`,
      margin: `1rem 0`,
    },
    width: `90%`,
    maxWidth: `1400px`,
    margin: `0 auto`,
    position: `relative`,

    '@media (min-width: 960px)': {
      top: `300px`,
      // background: `linear-gradient(to right,  #2b4bb6 0%,#0c0957 100%)`,
      // filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='#2b4bb6', endColorstr='#0c0957',GradientType=1 )`,
    },
    '@media (max-width: 959px)': {
      top: `80px`,
      // background: `linear-gradient(to right,  #2b4bb6 0%,#0c0957 100%)`,
      // filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='#2b4bb6', endColorstr='#0c0957',GradientType=1 )`,
    },
  },
  textField: {},
  formVisual: {
    '& img': {
      maxWidth: `90%`,
    },
  },
  footerCopyright: {
    textAlign: `center`,
    margin: `3rem auto`,
    color: `#fff`,
  },
}));

export default useStyles;
