import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { MEDIA_PATH } from '../../../Utils';

const useStyles = makeStyles((theme) => ({
  root: { padding: `0 !important` },
  institutionsList: {
    padding: 0,
  },
  institutionPicture: {
    margin: `1rem`,
    maxWidth: `100%`,
    '& img': {
      width: `100%`,
      // maxWidth: `445px`,
      height: `255px`,
      objectContain: `cover`,
      objectPosition: `100% 0`,
    },
  },
  institutionContent: {
    fontFamily: `Arial`,
    margin: `1rem`,

    '& .excerpt': {
      fontSize: `1.2rem`,
      color: `#777`,
    },
    '& h4': {
      fontSize: `1.5rem`,
    },
    '& h4, & a': {
      display: `block`,
      margin: `1rem 0`,
      color: `#011b75`,
    },
  },
}));

const InstitutionsGrid = (props) => {
  const classes = useStyles();
  const { entries } = props;

  return (
    <div className={classes.institutionsList}>
      <Grid container spacing={4}>
        {entries &&
          entries.map((entry) => {
            return (
              <Grid
                item
                lg={4}
                md={6}
                xl={4}
                xs={12}
                key={`list-item-${entry.id}`}
                className={classes.root}
              >
                <div className={classes.institutionPicture}>
                  <a href={`/institutii/${entry.slug}`}>
                    <img
                      alt="Product"
                      className={classes.image}
                      src={`${MEDIA_PATH}/${entry.pic_id_from_media_library}`}
                    />
                  </a>
                </div>
                <div className={classes.institutionContent}>
                  <h4>
                    <a href={`/institutii/${entry.slug}`}>{entry.title}</a>
                  </h4>
                  <h5>
                    <a href={`/institutii/${entry.slug}`}>
                      {entry.domeniu_activitate}
                    </a>
                  </h5>
                  {/*<p className={`excerpt`}>*/}
                  {/*  {getExcerptGrid(entry.procedura_1)}*/}
                  {/*</p>*/}
                  <p>
                    <a href={`/institutii/${entry.slug}`}>afla mai mult</a>
                  </p>
                </div>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default InstitutionsGrid;
